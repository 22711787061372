export default function formatNumberToIndianStyle(num: number | string): string {
    const numStr = num.toString().split(".");
    const integerPart = numStr[0];
    // const decimalPart = numStr.length > 1 ? "." + numStr[1] : "";

    let lastThreeDigits = integerPart.slice(-3);
    const otherDigits = integerPart.slice(0, -3);

    if (otherDigits !== '') {
        lastThreeDigits = ',' + lastThreeDigits;
    }

    const formattedNumber = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThreeDigits;
    return formattedNumber;
}