
import { useAppDispatch, useAppSelector } from "@store/store"
import { FaMicrophone, FaPaperPlane } from "react-icons/fa"
import { setListeningActiveState } from "@store/features/audio/audioInput"
import { setModalState } from "@store/features/modals/modalsRootSlice"
import getLanguageNameFromCode from "@functions/general/getLanguageNameFromCode"
import { useRecorder } from "@functions/audio/index"
import { FetchTranslatedSpeech } from "@api/bhashini/asrTranslation"
import { setMessage } from "@store/features/message/messageSlice"
import { changeSearchbarValue } from "@store/features/searchBar/SearchbarSlice"


const SearchBarMicInput = () => {
    const { audioBlob, recording, startRecording, stopRecording, blobToBase64 } = useRecorder();
    const dispatch = useAppDispatch()
    const language = useAppSelector(state => state.userDetails.preferredLanguage)

    //start recording within one second of component mounting
    setTimeout(startRecording, 1000)

    const stop_Recording = async () => {
        const returnedBlob = await stopRecording()
        if (returnedBlob) {
            const base64AudioWebmOpusCodec = await blobToBase64(returnedBlob)
            const translatedText = await FetchTranslatedSpeech(language!, base64AudioWebmOpusCodec!)
            if (translatedText) {
                dispatch(setMessage({ type: "success", content: "Translation successful", duration: 5 }))
                dispatch(changeSearchbarValue({ value: translatedText.output }))
            } else {
                dispatch(setMessage({ type: "error", content: "Translational services are down for now", duration: 8 }))
            }
        } else {
            dispatch(setMessage({ type: "error", content: "Audio services are down for now", duration: 8 }))
        }
        dispatch(setListeningActiveState({ status: false }))
    }


    return (
        <div className="absolute bg-fireai-blue z-40 h-full w-full rounded-lg flex flex-row justify-between">
            <div className="flex flex-col justify-center">
                <div className="flex flex-row">
                    <div className={`bg-white rounded-full p-5 ml-8 ${recording && 'animate-pulse'}`} onClick={recording ? stopRecording : startRecording}>
                        <FaMicrophone className="text-black text-xl" />
                    </div>
                    {(!recording && !audioBlob) && <p className="my-auto pl-4 text-2xl text-white">Please wait while we process...</p>}
                    {recording && !audioBlob && <p className="my-auto pl-4 text-2xl text-white">Listening in {getLanguageNameFromCode(language)}...</p>}
                    {(!recording && audioBlob) && <p className="my-auto pl-4 text-2xl text-white">Recording complete, processing...</p>}
                </div>
            </div>
            <div className="flex flex-col justify-between mr-5 mt-2 text-white">
                <div className="flex flex-row justify-end">
                    <span className="underline my-auto cursor-pointer" onClick={() => dispatch(setModalState({ modalName: "PreferredLanguageSelection", state: true }))}>Change Language</span>
                    <div className="border-2 border-white rounded-full h-8 w-8 pb-1 flex flex-col justify-center text-center ml-5" onClick={() => dispatch(setListeningActiveState({ status: false }))}>
                        <span className="text-xl p-0 m-0 cursor-pointer">&times;</span>
                    </div>
                </div>
                <div className="flex flex-row justify-end mb-2">
                    <FaPaperPlane className="text-white cursor-pointer hover:text-black" onClick={() => stop_Recording()} />
                </div>
            </div>
        </div>
    )
}

export default SearchBarMicInput