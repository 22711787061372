import { useEffect, useState } from "react";

// Function to request audio recording permission and initialize the recorder
async function requestRecorder() {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

    // Check if the browser supports audio/wav format
    const options = { mimeType: 'audio/flac' };
    if (!MediaRecorder.isTypeSupported(options.mimeType)) {
        console.warn(`${options.mimeType} is not supported on this browser. Defaulting to audio/webm format.`);
        options.mimeType = 'audio/webm'; // Fallback to webm if wav is not supported
    }

    return {
        worker: new MediaRecorder(stream, options),
        master: stream,
    };
}

// Custom hook for handling audio recording
export const useRecorder = () => {
    const [stream, setStream] = useState<MediaStream | null>(null);
    const [recording, setRecording] = useState(false);
    const [recorder, setRecorder] = useState<MediaRecorder | null>(null);
    const [audioBlob, setAudioBlob] = useState<Blob | null>(null); // Store audio as Blob

    // Handler function to save Blob data directly
    const handleData = (e: BlobEvent) => {
        const blob = e.data;
        setAudioBlob(blob); // Save the Blob
        setRecorder(null);  // Reset recorder
    };

    useEffect(() => {
        // Initialize recorder if it's not set yet
        if (recorder === null) {
            if (recording) {
                requestRecorder().then(({ worker, master }) => {
                    setRecorder(worker);
                    setStream(master);
                }).catch(console.error);
            }
            return;
        }

        // Attach dataavailable event handler and start/stop recording based on state
        if (recording) {
            recorder.addEventListener("dataavailable", handleData);
            recorder.start();
        } else {
            recorder.stop();
        }

        // Cleanup on component unmount or state change
        return () => {
            if (recorder) {
                recorder.removeEventListener("dataavailable", handleData);
            }
        };
    }, [recording, recorder]); // Only re-run effect if recording or recorder changes

    // Function to start recording
    function startRecording() {
        setRecording(true);
    }

    // Function to stop recording and return the processed audio blob
    function stopRecording(): Promise<Blob | null> {
        return new Promise((resolve) => {
            if (recorder && stream) {
                setRecording(false);
                stream.getAudioTracks().forEach((track) => track.stop());

                // Add event listener to resolve the promise when data is available
                recorder.addEventListener("dataavailable", (e: BlobEvent) => {
                    const blob = e.data;
                    setAudioBlob(blob); // Save the Blob
                    setRecorder(null);  // Reset recorder
                    resolve(blob);      // Resolve the promise with the blob
                }, { once: true });     // Only run once to prevent multiple triggers
            } else {
                resolve(null); // Resolve with null if no recorder or stream
            }
        });
    }

    const blobToBase64 = (blob: Blob): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result as string);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob); // Convert blob to Base64
        });
    };

    // Return the Blob, recording state, and control functions
    return { audioBlob, recording, startRecording, stopRecording, blobToBase64 };
};
