import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { message } from 'antd';
import { NoticeType } from "antd/es/message/interface";

export interface MessageProps {
    value: string
}

const initialState: MessageProps = {
    value: ""
}


export const MessageSlice = createSlice({
    name: "message",
    initialState,
    reducers: {
        setMessage: (state, action: PayloadAction<{ type: NoticeType, content: string, duration: number }>) => {
            state.value = action.payload.content
            message[action.payload.type](action.payload.content, action.payload.duration)
        }
    }
})

export default MessageSlice.reducer;
export const { setMessage } = MessageSlice.actions;