import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { PromptResponseTypes } from "@interfaces/api/dashboard/prompts";

export interface QueryResponseTypes {
    value: PromptResponseTypes | null,
    loadingState: boolean
}

const initialState: QueryResponseTypes = {
    value: null,
    loadingState: false
}


export const QueryResponseSlice = createSlice({
    name: "queryResponse",
    initialState,
    reducers: {
        changeQueryResponse: (state, action: PayloadAction<{ value: PromptResponseTypes | null }>) => {
            state.value = action.payload.value
        },
        changeQueryLoadingState: (state, action: PayloadAction<{ value: boolean }>) => {
            state.loadingState = action.payload.value
        }
    }
})

export default QueryResponseSlice.reducer;
export const { changeQueryResponse, changeQueryLoadingState } = QueryResponseSlice.actions;