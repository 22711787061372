import { getAxiosClient } from '@functions/api/axiosInstance'
import { AxiosResponse } from 'axios'
import { PromptResponseTypes } from '@interfaces/api/dashboard/prompts'
import ReactGA from 'react-ga4'

const FetchPromptResponse = async (databaseInstance: number, question: string): Promise<PromptResponseTypes | null> => {
    try {
        const _axios = await getAxiosClient()
        const promptTextsResponse: AxiosResponse<PromptResponseTypes> = await _axios.post<PromptResponseTypes>("/api/query", {
            previous_context: [],
            question: question,
            workspace: databaseInstance
        })
        ReactGA.event({ category: "API Request", action: "Query reponse", label: `Success to query: "${question}" on DB instance ${databaseInstance} in ${promptTextsResponse.headers['responseTime'] / 1000}s` });
        return promptTextsResponse.data
    }
    catch (err) {
        console.error("error while fetching prompt texts", err)
        ReactGA.event({ category: "API Request", action: "Query response", label: `Failure for ${question} on DB instance ${databaseInstance}` });
        return null
    }
}

export { FetchPromptResponse }

