import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit'
import SidebarReducer from "./features/sidebar/SidebarSlice";
import SearchbarReducer from "./features/searchBar/SearchbarSlice"
import ThemeReducer from "./features/thememode/themeSlice";
import DatabaseInstanceReducer from "./features/topbar/databaseInstance"
import QueryResponseReducer from "./features/reports/queryResponse"
import outletFilterReducer from "./features/reports/outletFilter"
import dateFilterReducer from "./features/reports/dateFilter"
import MessageReducer from "./features/message/messageSlice"
import contactusReducer from './features/topbar/contactus';
import audioAccessReducer from "./features/audio/microphoneAcessSlice"
import modalStateReducer from "./features/modals/modalsRootSlice"
import userDetailsSlice from "./features/user/userInfo"
import audioInputState from "./features/audio/audioInput"
import filterStore from './features/reports/filterStore';

export const store = configureStore({
    reducer: {
        sidebar: SidebarReducer,
        searchbar: SearchbarReducer,
        theme: ThemeReducer,
        databaseInstance: DatabaseInstanceReducer,
        queryResponse: QueryResponseReducer,
        outletFilter: outletFilterReducer,
        dateFilter: dateFilterReducer,
        message: MessageReducer,
        contactus: contactusReducer,
        audioAccess: audioAccessReducer,
        modalState: modalStateReducer,
        userDetails: userDetailsSlice,
        audioInputState: audioInputState,
        filterStore: filterStore
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<ReturnType<typeof store.getState>> = useSelector
