import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export interface AudioInputActiveProps {
    active: boolean
}

const initialState: AudioInputActiveProps = {
    active: false
}


export const AudioInputStateSlice = createSlice({
    name: "audioInput",
    initialState,
    reducers: {
        setListeningActiveState: (state, action: PayloadAction<{ status: boolean }>) => {
            state.active = action.payload.status
        }
    }
})

export default AudioInputStateSlice.reducer;
export const { setListeningActiveState } = AudioInputStateSlice.actions;