import { getAxiosClient } from '@functions/api/axiosInstance'
import { AxiosResponse } from 'axios'
import ReactGA from 'react-ga4'
import { ASRTranslationResponseType } from '@interfaces/api/bhashini'

const FetchTranslatedSpeech = async (source_language: string, audio: string): Promise<ASRTranslationResponseType | null> => {
    try {
        const _axios = await getAxiosClient()
        const TranslatedSpeech: AxiosResponse<ASRTranslationResponseType> = await _axios.post<ASRTranslationResponseType>("/api/bhashini/speechtotranslatedtext", {
            source_language: source_language,
            audio: audio
        })
        ReactGA.event({ category: "API Request", action: "Fetch preferred language list", label: `Success in ${TranslatedSpeech.headers['responseTime'] / 1000}s` });
        return TranslatedSpeech.data
    } catch (error) {
        console.log("error while fetching sidebar data", error);
        ReactGA.event({ category: "API Request", action: "Fetch preferred language list", label: "Failure" });
        return null;
    }
}

export { FetchTranslatedSpeech }