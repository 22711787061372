import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export interface SidebarProps {
    expanded: boolean
}

const initialState: SidebarProps = {
    expanded: false
}


export const SidebarSlice = createSlice({
    name: "sidebar",
    initialState,
    reducers: {
        toggleSideBar: (state, action: PayloadAction<{ open: boolean }>) => {
            state.expanded = action.payload.open
        }
    }
})

export default SidebarSlice.reducer;
export const { toggleSideBar } = SidebarSlice.actions;