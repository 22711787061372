import React, { useEffect, useState } from "react"
import ModalLayout from "@layouts/modalsLayout"
import { Select } from "antd"
import { RightArrow } from "@assets/index"
import { useAppDispatch, useAppSelector } from "@store/store"
import { setModalState } from "@store/features/modals/modalsRootSlice"
import { PreferredLanguageListType } from "@interfaces/api/bhashini"
import getLanguageNameFromCode from "@functions/general/getLanguageNameFromCode"
import { FetchPreferredLanguageList } from "@api/bhashini/fetchPreferredLanguageList"
import { SetPreferredLanguage } from "@api/bhashini/setPreferredLanguage"
import { setMessage } from "@store/features/message/messageSlice"
import { setUserLangauge } from "@store/features/user/userInfo"
import { setListeningActiveState } from "@store/features/audio/audioInput"

const PreferredLanguageSelection: React.FC<{ modalName: string }> = ({ modalName }) => {
    const dispatch = useAppDispatch()
    const user = useAppSelector(state => state.userDetails)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [preferredLanguage, setPreferredLanguage] = useState<any>()
    const [optionsLanguage, setOptionLanguage] = useState<string>()
    const [preferredList, setPreferredList] = useState<PreferredLanguageListType[] | []>([])
    useEffect(() => {
        const getPreferredList = async () => {
            const listItem = await FetchPreferredLanguageList()
            setPreferredList(listItem ? listItem : [])
        }
        getPreferredList()
    }, [])

    useEffect(() => {
        if (user) {
            setPreferredLanguage(user?.preferredLanguage)
        }
    }, [user])

    const setLanguageAndClose = async () => {
        console.log("Attempting to change the language to ", preferredLanguage)
        if (optionsLanguage != user.preferredLanguage) {
            if (optionsLanguage) {
                const response = await SetPreferredLanguage(optionsLanguage)
                if (response) {
                    dispatch(setUserLangauge({ preferredLanguage: optionsLanguage }))
                    dispatch(setModalState({ modalName: 'PreferredLanguageSelection', state: false }))
                    dispatch(setMessage({ type: 'success', content: response.message, duration: 5 }))
                }
            }
            dispatch(setListeningActiveState({ status: true }))
        }

    }

    return (
        <div className="absolute h-screen w-screen bg-[#00000080] z-50 flex flex-col justify-center">
            <ModalLayout modalIdentifier={modalName}>
                <>
                    <h1 className="text-3xl pb-2 font-semibold">Pick Speech Language</h1>
                    {preferredList.length > 1 ?
                        <Select defaultValue={preferredLanguage} className="w-2/5" value={optionsLanguage} onChange={(value: string) => setOptionLanguage(value)}>
                            {preferredList.map((list: PreferredLanguageListType) => <Select.Option value={list.language}>{getLanguageNameFromCode(list.language)}</Select.Option>)}
                        </Select> : <p>Please wait, loading language list..</p>}
                    <button type="button" className="flex flex-row bg-fireai-blue text-white rounded-full px-4 py-1 mt-5 text-sm" onClick={setLanguageAndClose}>
                        <span className="pr-2 my-auto">Ask Query</span>
                        <img alt="Arrow showing right arrow" src={RightArrow} className="h-1/2 w-5 my-auto" />
                    </button>
                </>
            </ModalLayout>
        </div>
    )
}

export default PreferredLanguageSelection