import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export interface OutletFilterTypes {
    outletFilter: string | null
}

const initialState: OutletFilterTypes = {
    outletFilter: null
}

const outletFilterSlice = createSlice({
    name: "outletFilter",
    initialState,
    reducers: {
        changeOutletFilter: (state, action: PayloadAction<{ value: string }>) => {
            state.outletFilter = action.payload.value
        },
        clearOutletFilter: (state) => {
            state.outletFilter = null
        }
    }
})

export default outletFilterSlice.reducer;
export const { changeOutletFilter, clearOutletFilter } = outletFilterSlice.actions;