import { changeOutletFilter, clearOutletFilter } from '@store/features/reports/outletFilter';
import { useAppSelector } from '@store/store';
import { ConfigProvider, Select, theme } from 'antd';
import { useDispatch } from 'react-redux';
import { OutletDataType } from '@interfaces/api/reports/detailedResponse';
import { useMemo } from 'react';

const OutletSelector: React.FC<{ isLoading: boolean, outletArray: OutletDataType[] }> = ({ isLoading, outletArray }) => {
    const themeMode = useAppSelector((state) => state.theme.themeMode);
    const dispatch = useDispatch();
    const outletFilter = useAppSelector((state) => state.outletFilter.outletFilter);
    const memoizedoutletData = useMemo(() => {
        if (outletArray) {
            return [{ name: 'Select All' }, ...outletArray];
        }
        return null;
    }, [outletArray]);

    const handleSelectChange = (value: string) => {
        console.log(value);
        if (value === "Select All") {
            dispatch(clearOutletFilter());
        } else {
            dispatch(changeOutletFilter({ value: value }));
        }
    };

    const longestOptionLength = useMemo(() => {
        if (memoizedoutletData) {
            return Math.max(...memoizedoutletData.map(item => item.name.length));
        }
        return 0;
    }, [memoizedoutletData]);

    const selectWidth = `${longestOptionLength + 5}ch`;

    return (
        <ConfigProvider
            theme={{
                algorithm: themeMode === "light" ? theme.defaultAlgorithm : theme.darkAlgorithm,
            }}
        >
            <Select
                disabled={isLoading}
                allowClear={true}
                placeholder="Select outlet"
                className="w-[200px] xs:w-[100px] md:w-fit md:min-w-[150px] border-[1px] border-fireai-blue rounded-lg outline-none"
                onChange={handleSelectChange}
                options={memoizedoutletData?.map((item) => {
                    return {
                        label: item.name,
                        value: item.name
                    };
                })}
                dropdownStyle={{ width: selectWidth, alignItems: "center" }}
                dropdownAlign={{
                    points: ['tr', 'br'], // align dropdown left edge to the Select component right edge
                    offset: [0, 0], // same vertical position
                    overflow: {
                        adjustX: 0, // do not adjust
                        adjustY: 0, // do not adjust
                    },
                }}
                value={outletFilter === null ? "Select Outlet" : outletFilter}
            />
        </ConfigProvider>
    );
};

export default OutletSelector;
