import { createFileRoute } from '@tanstack/react-router'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'

export const Route = createFileRoute('/logout')({
  component: Logout
})

function Logout() {
  ReactGA.send({ hitType: "pageview", page: "/logout", title: "User logout" });
  useEffect(() => {
  })
  return (
    <div className=''>
      {/* <h1 className=''>logging out</h1> */}
    </div>
  )
}