import React, { ReactElement } from "react";
import Sidebar from "@components/common/sidebar";
import Topbar from "@components/common/topbar";
import { useAppDispatch } from "@store/store";
import { toggleSideBar } from "@store/features/sidebar/SidebarSlice";

const BaseLayout: React.FC<{ children: ReactElement }> = ({ children }) => {
    const dispatch = useAppDispatch()
    return (
        <div className="flex flex-col dark:bg-[#000000] dark:text-white h-screen">
            <div className="grid grid-cols-12 gap-1 h-full">
                <div id="sidebarContainer" className="col-span-12 fixed md:w-14 z-30 bg-fireai-grey dark:bg-[#1D1D1D]">
                    <Sidebar />
                </div>
                <div id="mainContentContainer" onClick={() => (window.innerWidth > 767) && dispatch(toggleSideBar({ open: false }))} className="col-span-12 relative left-0 sm:left-[5%] md:w-[95%]">
                    <div id="topNavContainer">
                        <Topbar />
                    </div>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default BaseLayout