import { PayloadAction, createSlice } from "@reduxjs/toolkit"

type TypeSuggestiveTexts = {
    descriptiveText: string,
    textId: number
}

export interface databaseInstanceProps {
    id: number,
    suggestiveTexts: TypeSuggestiveTexts[]
}

const initialState: databaseInstanceProps = {
    id: 1,
    suggestiveTexts: []
}


export const databaseInstance = createSlice({
    name: "databaseInstance",
    initialState,
    reducers: {
        changeDatabaseInstance: (state, action: PayloadAction<{ id: number, suggestiveTexts: TypeSuggestiveTexts[] }>) => {
            state.id = action.payload.id
            state.suggestiveTexts = action.payload.suggestiveTexts
        }
    }
})

export default databaseInstance.reducer;
export const { changeDatabaseInstance } = databaseInstance.actions;