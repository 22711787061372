import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export interface SearchbarProps {
    value: string
}

const initialState: SearchbarProps = {
    value: ""
}


export const SearchbarSlice = createSlice({
    name: "searchbar",
    initialState,
    reducers: {
        changeSearchbarValue: (state, action: PayloadAction<{ value: string }>) => {
            state.value = action.payload.value
        },
        resetSearchbarValue: (state) => {
            state.value = ""
        }
    }
})

export default SearchbarSlice.reducer;
export const { changeSearchbarValue, resetSearchbarValue } = SearchbarSlice.actions;