// import TemplateReport from '@components/reports/TemplateReport'
// import VendorReport from '@components/reports/VendorReport';
import { createFileRoute } from '@tanstack/react-router';

// let companyName = ""
// let pageData = ""
// let templateId = ""

export const Route = createFileRoute('/reports/$corp/')({
  // beforeLoad: ({ params }) => {
  //   const { corp, reports, tempid } = params;

  //   companyName = corp
  //   pageData = reports
  //   templateId = tempid

  //   return { companyName, pageData, templateId };
  // },
  // loader: async ({ params }) => {
  //   const { corp, reports, tempid } = params;

  //   return {
  //     companyName: corp,
  //     currentPageData: reports,
  //     templateId: tempid
  //   };
  // },
  component: () => {
    return <>hieee</>
  },
});