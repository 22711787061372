import { RxHamburgerMenu } from "react-icons/rx";
import { ConfigProvider, Select, theme } from "antd"
import { FireAI_Logo_light } from "@assets/index"
import { useAppDispatch, useAppSelector } from "@store/store";
import { toggleSideBar } from "@store/features/sidebar/SidebarSlice"
import { TopbarOptions } from "@data/common/topbar";
import { FetchDataBaseInstance } from "@api/common/topBar"
import { Skeleton } from 'antd';
import { useEffect, useState } from "react";
import { DropdownOptionsTypes } from "@interfaces/components/common/topbar"
import { changeDatabaseInstance } from "@store/features/topbar/databaseInstance"
import { resetSearchbarValue } from "@store/features/searchBar/SearchbarSlice";
import { changeQueryResponse } from "@store/features/reports/queryResponse";
import ContactUsModal from "./ContactUsModal";
import { toggleContactUs } from "@store/features/topbar/contactus";
import { DatabaseInstanceType } from "@interfaces/api/common/databaseinstances";
import { Logout } from '@api/auth/logout'

const Topbar = () => {
    const dispatch = useAppDispatch()
    const databaseInstance = useAppSelector((state) => state.databaseInstance.id)

    const [DBSuggestions, setDBSuggestions] = useState<DatabaseInstanceType[]>();

    // const [isModalOpen, setIsModalOpen] = useState(false);
    const isModalOpen = useAppSelector((state) => state.contactus.isOpen)

    const handleOpenModal = () => {
        dispatch(toggleContactUs({ open: true }))
    };

    const handleCloseModal = () => {
        dispatch(toggleContactUs({ open: false }))
    };

    const [databaseInstances, setDataBaseInstances] = useState<DropdownOptionsTypes[] | []>([])
    useEffect(() => {
        const fetchDataBaseInstances = async () => {
            const instances = await FetchDataBaseInstance()
            if (instances.length > 0) {
                dispatch(changeDatabaseInstance({ id: instances[0].instanceId, suggestiveTexts: instances[0].suggestiveTexts }))
                setDBSuggestions(instances);
            }
            const dropdownOptions: DropdownOptionsTypes[] = instances.length > 0 ? instances.map(option => {
                return (
                    {
                        label: option.instanceName,
                        value: option.instanceId
                    })
            }) : []
            setDataBaseInstances(dropdownOptions)
        }
        fetchDataBaseInstances()
    }, [dispatch])
    const sidebarStatus = useAppSelector((state) => state.sidebar.expanded)
    const toggleSidebarFunction = (isOpen: boolean) => {
        dispatch(toggleSideBar({ open: isOpen }))
    }
    const themeMode = useAppSelector((state) => state.theme.themeMode)

    function resetPageValues(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        e.preventDefault()
        dispatch(resetSearchbarValue())
        dispatch(changeQueryResponse({ value: null }))
        if (window.location.pathname !== "/dashboard") history.pushState({}, "", "/dashboard")
    }

    return (
        <>
            <div className="flex flex-row-reverse md:flex-row justify-between mt-4 z-20 font-medium">
                <div className="sm:px-10 flex flex-row">
                    <ConfigProvider
                        theme={{
                            algorithm: themeMode === "light" ? theme.defaultAlgorithm : theme.darkAlgorithm,
                        }}
                    >
                        {(databaseInstances.length > 0 && window.location.pathname === "/dashboard") ?
                            <Select
                                className="w-[100px] 2xs:w-[125px] xs:w-[200px] md:min-w-[150px] border-[1px] border-fireai-blue rounded-lg outline-none"
                                onChange={selection => {
                                    dispatch(changeDatabaseInstance({ id: selection, suggestiveTexts: DBSuggestions!.find((instance) => instance.instanceId === selection)!.suggestiveTexts }))
                                    dispatch(resetSearchbarValue());
                                    dispatch(changeQueryResponse({ value: null }))
                                }}
                                options={databaseInstances}
                                value={databaseInstance}
                            /> : (window.location.pathname === "/dashboard") && <div><Skeleton.Input active size="default" block={true} /></div>}
                    </ConfigProvider>
                    <RxHamburgerMenu className="ml-4 mr-2 my-auto text-xl text-fireai-blue inline md:hidden" onClick={() => toggleSidebarFunction(!sidebarStatus)} />
                </div>
                <div className="ml-4 xs:ml-6 smx:ml-8 mr-1.5 md:ml-6 mt-2 md:hidden">
                    <img alt="bla" src={FireAI_Logo_light} onClick={resetPageValues}
                        className="sm:h-[30px] h-[25px]" />
                </div>
                <div className="hidden md:flex flex-row-reverse">
                    {TopbarOptions.map((option, index) => {
                        if (option.itemName === 'LOGOUT') {
                            return <span className="mr-5 text-sm bg-fireai-blue text-white px-4 py-2 my-auto rounded-3xl cursor-pointer hover:bg-gray-300 hover:text-fireai-blue" onClick={Logout} key={index}>{option.itemName}</span>
                        }
                        else if (option.itemName === 'PRODUCT DEMO') {
                            return <span className="mr-5 text-sm text-fireai-blue dark:text-[#ffffff] my-auto cursor-pointer hover:text-gray-500" key={index} onClick={handleOpenModal}>{option.itemName}</span>
                        }
                        else {
                            return <span className="mr-5 text-sm text-fireai-blue dark:text-[#ffffff] my-auto cursor-pointer hover:text-gray-500" key={index}>{option.itemName}</span>
                        }
                    })
                    }
                </div>
                <ContactUsModal isOpen={isModalOpen} onClose={handleCloseModal} />
            </div>
        </>
    )
}

export default Topbar