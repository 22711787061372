import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export interface AudioAccessProps {
    access: boolean
}

const initialState: AudioAccessProps = {
    access: false
}


export const AudioAccessSlice = createSlice({
    name: "audioAccess",
    initialState,
    reducers: {
        setAccessState: (state, action: PayloadAction<{ access: boolean }>) => {
            state.access = action.payload.access
        }
    }
})

export default AudioAccessSlice.reducer;
export const { setAccessState } = AudioAccessSlice.actions;