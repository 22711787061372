/* eslint-disable @typescript-eslint/no-unused-vars */
import { GraphData } from "@interfaces/components/reports/page";
import { useAppSelector } from "@store/store";
import { ApexOptions } from "apexcharts";
import React from "react";
import ReactApexChart from "react-apexcharts";


interface StackedBarProps {
    graphdata: GraphData;
}


const NewBarChart: React.FC<StackedBarProps> = ({ graphdata }) => {
    console.log(graphdata);
    const themeMode = useAppSelector((state) => state.theme.themeMode);
    const { x, y } = graphdata;
    const filteredSeries: ApexAxisChartSeries = [{
        name: graphdata.barLabelName,
        data: y ? y.map((item) => {
            const num = Number(item);
            return Number(num.toFixed(2));
        }) : [],
    }];
    const chartOptions: ApexOptions = {
        chart: {
            type: "bar",
            height: 100,
            stacked: false,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                dataLabels: {
                    total: {
                        enabled: true,
                        offsetX: 0,
                        style: {
                            fontSize: "2px",
                            fontWeight: 10,
                        },
                    },
                },
            },
        },
        theme: {
            mode: themeMode === "light" ? "light" : "dark",
        },
        title: {
            text: graphdata.graphTitle,
        },
        xaxis: {
            categories: x,
            labels: {
                rotate: -65,  // Adjust label rotation if necessary
                style: {
                    fontSize: "10px",
                },
                formatter: function (val: string) {
                    const returnVal = val.slice(0, 15);
                    return returnVal; // Adjust label length if necessary
                },
            },
            title: {
                text: graphdata.xlabel,
            },
        },
        yaxis: {
            logarithmic: true,
            title: {
                text: graphdata.ylabel,
            },
            labels: {
                formatter: function (val: number) {
                    return val.toFixed(2);
                },
            }
        },
        fill: {
            opacity: 1,
        },
        legend: {
            position: "top",
            horizontalAlign: "left",
            offsetX: 0,
        },
        dataLabels: {
            enabled: false,
        }
    };

    return (
        <div>
            <div id="chart" className="w-full border-2 border-[#CACACA] rounded-lg my-2 p-2">
                <ReactApexChart
                    options={chartOptions}
                    series={filteredSeries}
                    type="bar"
                    height={400}
                />
            </div>
        </div>
    );
}

export default NewBarChart
