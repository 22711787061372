import React from "react";
import { ToolbarRightOptions, QueryToolbarFilterOptions, ToolbarLeftOptions } from "@data/dashboard/QueryToolBar";

const QueryToolbar: React.FC<{
  data: { [key: string]: unknown }[];
  reportName: string;
}> = ({ data, reportName }) => {
  return (
    <div className="flex flex-row justify-between text-gray-600 dark:text-gray-300">
      <div className="leftBar flex flex-row">
        {QueryToolbarFilterOptions.map((item, index) => (
          <span key={index} className="cursor-pointer my-auto text-3xl mr-2">
            {item.itemIcon}
          </span>
        ))}
      </div>
      <div className="rightBar flex flex-row">
        <div className="_left flex flex-row mr-5">
          {ToolbarLeftOptions.map((item, index) => (
            <div
              key={index}
              className="flex flex-row mr-3 cursor-pointer"
              // @ts-expect-error works
              {...(item.onClick ? { onClick: () => item.onClick(data, reportName) } : {})}
            >
              <span className="text-2xl my-auto mr-1">{item.itemIcon}</span>
              <span className="my-auto mr-1 hidden sm:block">{item.itemLabel}</span>
            </div>
          ))}
        </div>
        <div className="_right flex flex-row">
          {ToolbarRightOptions.map((item, index) => (
            <div
              key={index}
              className="cursor-pointer my-auto text-2xl mr-2"
              // @ts-expect-error works
              {...(item.onClick ? { onClick: () => item.onClick(data) } : {})}
            >
              {item.itemIcon}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default QueryToolbar;
