import outlet_icon from '@assets/sidebar/KPI/outlet.png'
import formatNumberToIndianStyle from '@functions/reports/indianCurrencyFormat'
import KPIlayout from "@data/reports/KPIlayout"
import { KPIdataType } from '@interfaces/api/reports/detailedResponse'

type KPIcardProps = {
  label: string,
  value: number | string,
  index: number,
  bgColor?: string,
  classes: string
}

type KPIpanelProps = {
  kpi: KPIdataType[]
}

const kpiCardBgColor = ['bg-[#FE6A54]', 'bg-[#02C174]', 'bg-[#FF9900]', 'bg-fireai-blue']
let colorIndex = 0;

const KPIpanel: React.FC<KPIpanelProps> = ({ kpi }) => {
  const kpiClasses = KPIlayout(kpi.length);
  return (
    <div className="flex justify-around flex-wrap gap-x-5 my-5 text-center w-full items-center">
      <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 ${kpi.length > 4 ? "grid-rows-2" : "grid-rows-1"} w-full gap-2 `}>
        {
          kpi.slice(0, 6).map((item, index) => {
            return (
              <KPIcard
                classes={kpiClasses[index]}
                index={index}
                key={index}
                label={item.label}
                value={item.value}
                bgColor={kpiCardBgColor[colorIndex++ % 4]}
              />
            );
          })}
      </div>
    </div>
  );
};

const KPIcard: React.FC<KPIcardProps> = ({
  label,
  value,
  index,
  classes,
  bgColor = "bg-fireai-blue",
}) => {
  return (
    <div
      className={`text-white ${bgColor} py-4 w-full rounded-[12px] flex flex-col justify-center shadow-md ${classes}`}
      key={index}
    >
      <div className="text-center mx-auto w-11/12 px-2">
        <p className="text-lg font-medium">{label}</p>
      </div>
      <div className="text-white w-full flex flex-row justify-center mx-auto text-center mt-2">
        <img src={outlet_icon} alt="Sidebar home" className="mr-2 w-8 h-8" />
        <p className="text-2xl my-auto">{formatNumberToIndianStyle(value)}</p>
      </div>
    </div>
  );
};


export default KPIpanel