// axiosInstance.ts
import axios from 'axios';

const getAxiosClient = async (type?: string) => {
    let axiosHeaders
    if (type && type === 'multipart') {
        axiosHeaders = {
            'Content-Type': 'multipart/form-data',
        }
    } else {
        axiosHeaders = {
            'Content-Type': 'application/json'
        }
    }
    const _axios = axios.create({
        baseURL: import.meta.env.VITE_BASE_URL,
        headers: axiosHeaders,
        withCredentials: true
    });

    _axios.interceptors.request.use((config) => {
        config.headers['requestTime'] = performance.now().toString(); // Store as string
        return config;
    });

    _axios.interceptors.response.use((response) => {
        const start = parseFloat(response.config.headers['requestTime']); // Parse the stored string back to float
        const end = performance.now();
        const responseTime = Math.round(end - start); // Calculate the response time in milliseconds

        // Add responseTime to the response headers
        response.headers['responseTime'] = responseTime.toString();
        return response;
    }, (error) => {
        if (error.response) {
            const start = parseFloat(error.response.config.headers['requestTime']);
            const end = performance.now();
            const responseTime = Math.round(end - start);

            error.response.headers['responseTime'] = responseTime.toString();

            if (error.data && error.response.status === 401) {
                window.location.href = "/session-expired"
            }
        }
        return Promise.reject(error);
    });

    return _axios;
};

export { getAxiosClient };
