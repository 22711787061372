import { getAxiosClient } from '@functions/api/axiosInstance'
import { AxiosResponse } from 'axios'
import ReactGA from 'react-ga4'


const Logout = async (): Promise<null> => {
    try {
        const _axios = await getAxiosClient()
        const AuthModesResponse: AxiosResponse = await _axios.post("/auth/logout")
        window.location.assign("/")
        ReactGA.event({ category: "API Request", action: "Logout", label: `Success in ${AuthModesResponse.headers['responseTime'] / 1000}s` });
        return null
    } catch (error) {
        console.log("error while fetching Auth Modes Data", error);
        ReactGA.event({ category: "API Request", action: "Logout", label: "Failure" });
        return null;
    }
}

export { Logout }