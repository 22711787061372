import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type ThemeEnum = {
    themeMode: "light" | "dark" | null;
}

const initialState:ThemeEnum = {
    themeMode: null
}

export const themeSlice = createSlice({
    name: "theme",
    initialState,
    reducers: {
        toggleTheme: (state, action: PayloadAction<ThemeEnum>) => {
            state.themeMode = action.payload.themeMode
        }
    }
})

export default themeSlice.reducer;
export const { toggleTheme } = themeSlice.actions;