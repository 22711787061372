// import { SidebarBottomTypes } from "@interfaces/components/common/sidebar"
import {
  BiHomeAlt,
  Bullet_Points,
  FiBox,
  LuHistory,
  HiOutlineViewGridAdd,
  TbLogout,
} from "@assets/index";
import { ReactElement } from "react";

type LogosType = {
  [key: string]: ReactElement;
};

const sidebarLogos: LogosType = {
  BiHomeAlt: (
    <BiHomeAlt
      size={20}
      className="text-gray-500 group-hover:text-fireai-blue group-hover:scale-125 transition-all duration-75 ease-linear"
    />
  ),
  Bullet_Points: (
    <img
      src={Bullet_Points}
      alt="Sidebar bullets"
      className="mx-auto w-3 font-black"
    />
  ),
  HiOutlineViewGridAdd: (
    <HiOutlineViewGridAdd
      size={22}
      className="text-gray-500 group-hover:text-fireai-blue group-hover:scale-125 transition-all duration-75 ease-linear"
    />
  ),
  LuHistory: (
    <LuHistory
      size={20}
      className="text-gray-500 group-hover:text-fireai-blue group-hover:scale-125 transition-all duration-75 ease-linear"
    />
  ),
  FiBox: (
    <FiBox
      size={20}
      className="text-gray-500 group-hover:text-fireai-blue group-hover:scale-125 transition-all duration-75 ease-linear font-bold"
    />
  ),
  TbLogout: (
    <TbLogout
      size={20}
      className="text-gray-500 group-hover:text-fireai-blue group-hover:scale-125 transition-all duration-75 ease-linear"
    />
  ),
};

export { sidebarLogos };
