import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export interface ModalStateProps {
    bhashiniMicrophoneAccess: boolean,
    preferredLanguageInfo: boolean,
    PreferredLanguageSelection: boolean
}

const initialState: ModalStateProps = {
    bhashiniMicrophoneAccess: false,
    preferredLanguageInfo: false,
    PreferredLanguageSelection: false
}


export const ModalStateProps = createSlice({
    name: "modalState",
    initialState,
    reducers: {
        setModalState: (state, action: PayloadAction<{ modalName: string, state: boolean }>) => {
            state[action.payload.modalName] = action.payload.state
        }
    }
})

export default ModalStateProps.reducer;
export const { setModalState } = ModalStateProps.actions;