import { getAxiosClient } from '@functions/api/axiosInstance'
import { AxiosResponse } from 'axios'
import ReactGA from 'react-ga4'
import { setPreferredLanguageResponseType } from '@interfaces/api/bhashini'

const SetPreferredLanguage = async (preferLanguage: string): Promise<setPreferredLanguageResponseType | null> => {
    try {
        const _axios = await getAxiosClient()
        const PreferredLanguage: AxiosResponse<setPreferredLanguageResponseType> = await _axios.put<setPreferredLanguageResponseType>("/api/user/preferredLanguage", {
            language: preferLanguage
        })
        ReactGA.event({ category: "API Request", action: "Set preferred language", label: `Success in ${PreferredLanguage.headers['responseTime'] / 1000}s` });
        return PreferredLanguage.data
    } catch (error) {
        console.log("error while setting preferred language: ", error);
        ReactGA.event({ category: "API Request", action: "Set preferred language", label: "Failure" });
        return null;
    }
}

export { SetPreferredLanguage }