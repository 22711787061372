import { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { setModalState } from "@store/features/modals/modalsRootSlice";

const ModalLayout: React.FC<{ children: ReactNode, modalIdentifier: string }> = ({ children, modalIdentifier }) => {
    const dispatch = useDispatch();
    console.log("Modal ID: ", modalIdentifier);

    const modalSlicePayload = { modalName: modalIdentifier, state: false };

    return (
        <div className="relative min-h-32 w-1/3 bg-white mx-auto text-left px-10 py-8">
            <span onClick={() => dispatch(setModalState(modalSlicePayload))} className="absolute right-3 top-1 text-3xl cursor-pointer hover:text-fireai-blue">&times;</span>
            {children}
        </div>
    );
};

export default ModalLayout;
