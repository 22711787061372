import { createFileRoute } from '@tanstack/react-router'
import TemplateReport from '@components/reports/TemplateReport'
import VendorReport from '@components/reports/VendorReport';

let companyName = ""
let pageData = ""
let templateId = ""
export const Route = createFileRoute('/reports/$corp/$reports/$tempid')({
  beforeLoad: ({ params }) => {
    const { corp, reports, tempid } = params;

    companyName = corp
    pageData = reports
    templateId = tempid

    return { companyName, pageData, templateId };
  },
  loader: async ({ params }) => {
    const { corp, reports, tempid } = params;

    return {
      companyName: corp,
      currentPageData: reports,
      templateId: tempid
    };
  },
  component: () => {
    return templateId === '1' ? <VendorReport reports={pageData} company={companyName} /> : <TemplateReport companyName={companyName} reportName={pageData} />;
  },
});