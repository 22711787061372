import { getAxiosClient } from '@functions/api/axiosInstance'
import { AxiosResponse } from 'axios'
import { DetailedResponseType, FetchDetailedReportsTypes } from '@interfaces/api/reports/detailedResponse'
import ReactGA from 'react-ga4'


const FetchDetailedReport = async (props?: FetchDetailedReportsTypes) => {
    const encodeParams = (params: { [key: string]: string }) => {
        return Object.keys(params)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
            .join('&');
    };
    // Define your parameters
    const params = {
        fromDate: props?.fromDate ? (String(props?.fromDate)) : "",
        toDate: props?.toDate ? (String(props?.toDate)) : "",
        outletName: props?.outlet ? (String(props?.outlet)) : ""
    };

    // Manually encode the parameters
    const encodedParams = encodeParams(params);
    try {
        const _axios = await getAxiosClient()
        const fetchedDetails: AxiosResponse<DetailedResponseType> = await _axios.get<DetailedResponseType>(`/api/reports/${props?.orgName}/${props?.reportName}?${encodedParams}`)
        ReactGA.event({ category: "API Request", action: "Query reponse", label: `Success to report ${props?.orgName}/${props?.reportName}?${encodeParams} ${fetchedDetails.headers['responseTime'] / 1000}s` });
        return fetchedDetails.data
    }
    catch (err) {
        console.error("error while fetching prompt texts", err)
        return null
    }
}

export { FetchDetailedReport }