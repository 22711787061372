import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export interface DateFilterTypes {
    fromDate: string | null,
    toDate: string | null
}

const initialState: DateFilterTypes = {
    fromDate: null,
    toDate: null
}

const dateFilterSlice = createSlice({
    name: "dateFilter",
    initialState,
    reducers: {
        changeFromDate: (state, action: PayloadAction<{ value: string }>) => {
            state.fromDate = action.payload.value
        },
        changeToDate: (state, action: PayloadAction<{ value: string }>) => {
            state.toDate = action.payload.value
        },
        changeDateFilter: (state, action: PayloadAction<{ fromDate: string | null, toDate: string | null}>) => {
            state.fromDate = action.payload.fromDate
            state.toDate = action.payload.toDate
        },
        clearDateFilter: (state) => {
            state.fromDate = null
            state.toDate = null
        }
    }
})

export default dateFilterSlice.reducer;
export const { changeFromDate, changeToDate, changeDateFilter, clearDateFilter } = dateFilterSlice.actions;