/* eslint-disable @typescript-eslint/no-explicit-any */
import { createFileRoute } from "@tanstack/react-router";
import { FetchAuthModes, LoginUser, RegisterUser } from "@api/auth/modes";
import { authModesTypes } from "@interfaces/api/auth";
import CobeItem from "@assets/login/globe";
import ReactGA from "react-ga4";
import { useAppDispatch } from "@store/store";
import { TypeAnimation } from "react-type-animation";
import { setMessage } from "@store/features/message/messageSlice";
import { useEffect, useState } from "react";
import { getAxiosClient } from "@functions/api/axiosInstance";
import { UserDetails } from "@interfaces/components/common/sidebar";

export const Route = createFileRoute("/")({
  component: () => {
    return <Index />;
  },
});

type CredType = {
  userId: string;
  password: string;
  name?: string;
}

function Index() {

  const dispatch = useAppDispatch();
  const [data, setData] = useState<authModesTypes[] | null>();
  useEffect(() => {
    const fetchData = async () => {
      const dataAuth: authModesTypes[] | null = await FetchAuthModes();
      setData(dataAuth);
      console.log(dataAuth);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const checkUserLoggedIn = async () => {
      try {
        const _axios = await getAxiosClient();
        const isUser = await _axios.get<UserDetails>("/api/user/details");
        if (isUser) {
          history.pushState({}, "", "/dashboard");
        }
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          history.pushState({}, "", "/");
          return false;
        } else if (error.response) {
          console.error("error ", error);
          history.pushState({}, "", "/session-expired");
        } else {
          console.error("Network error or other issue: ", error);
          history.pushState({}, "", "/session-expired");
        }
      }
    };

    checkUserLoggedIn();
  }, []);
  // setIsLoggedIn(false);
  ReactGA.send({
    hitType: "pageview",
    page: `${window.location.pathname + window.location.search}`,
    title: "Login page of FireAI",
  });

  const [creds, setCreds] = useState<CredType>({
    userId: "",
    password: "",
  })
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [method, setMethod] = useState("social");

  const handleLogin = async (e) => {
    e.preventDefault();
    if (creds.userId === "" || creds.password === "") {
      dispatch(
        setMessage({
          type: "error",
          content: "Please enter valid credentials",
          duration: 2,
        })
      );
      return;
    }
    if (method === "login") {
      try {
        const res = await LoginUser({ email: creds.userId, password: creds.password });
        if (String(res?.status)[0] === "2") {
          window.location.assign("/dashboard");
        }
        else {
          dispatch(
            setMessage({
              type: "error",
              content: "Invalid credentials or user does not exist",
              duration: 2,
            })
          );

        }
      }
      catch (err) {
        console.log(err);
      }
    }
    if (method === "signup") {
      try {
        const res = await RegisterUser({ email: creds.userId, password: creds.password, name: creds.name });
        if (String(res?.status)[0] === "2") {
          try {
            const res = await LoginUser({ email: creds.userId, password: creds.password });
            if (String(res?.status)[0] === "2") {
              window.location.assign("/dashboard");
            }
          }
          catch (err) {
            console.log(err);
          }
        }
        else {
          dispatch(
            setMessage({
              type: "error",
              content: "User already exists",
              duration: 2,
            })
          );
        }
      }
      catch (err) {
        console.log(err);
      }
    }
  };



  return (
    <div className="w-screen h-screen grid grid-cols-1 md:grid-cols-3 bg-black justify-center md:overflow-hidden">
      <div className="col-span-2 hidden md:flex flex-col justify-start overflow-hidden">
        <div className="ml-30 pl-36 pt-36">
          <span className="mt-20 font-bold text-4xl text-white">
            After you use it, all you will say is <br />
            "FireAI is{" "}
            <TypeAnimation
              sequence={[
                "fast",
                1000,
                "secure",
                1000,
                "dependable",
                1000,
                "easy to use",
                1000,
              ]}
              wrapper="span"
              speed={10}
              repeat={Infinity}
            />
            ".
          </span>
        </div>
        <div>
          <CobeItem />
        </div>
      </div>
      <div className="col-span-1 flex flex-col justify-center">
        {/* Container for FireAI Logo */}
        <div className="text-center mx-auto bg-gray-300 rounded-md py-5 relative w-11/12 md:w-10/12">
          <span className="absolute font-bold text-gray-700 rounded-full bg-gray-300 shadow-lg flex items-center justify-center font-mono h-[80px] w-[80px] mt-[-60px] left-1/2 transform -translate-x-1/2">
            <img
              src="https://ik.imagekit.io/fireai/fireai_logo_mini.png?updatedAt=1721489537089"
              alt="FireAI Logo"
              className="w-5/12"
            />
          </span>
          <h1 className="text-lg font-medium text-gray-700 pt-8">
            Get started with FireAI
          </h1>
          <h3 className="text-md text-gray-700 font-bold mt-2">
            {method !== "social" ? method.toLocaleUpperCase() : null}
          </h3>
          {method === 'social' ?
            <div id="SocialLogins" className="text-center">
              {data
                ? data.map((mode: authModesTypes, index: number) => {
                  if (
                    mode.strategy !== "Sign In" &&
                    mode.strategy !== "Sign Up"
                  ) {
                    return (
                      <div
                        key={index}
                        className="w-10/12 my-3 rounded-md  mx-auto hover:text-gray-300 hover:bg-black border border-black hover:border-fireai-blue shadow-sm hover:shadow-xl cursor-pointer"
                      >
                        <span
                          className="rounded-full flex flex-row justify-center gap-1 p-3 mx-auto"
                          onClick={() =>
                            mode.disabled
                              ? dispatch(
                                setMessage({
                                  type: "error",
                                  content: mode.disabledMessage!,
                                  duration: 5,
                                })
                              )
                              : window.location.assign(
                                `${mode.strategyLoginLink}`
                              )
                          }
                        >
                          <img
                            src={mode.strategyImage}
                            className="ml-5"
                            alt={mode.strategy}
                            height={25}
                            width={25}
                          />
                          <span className="ml-3 font-medium my-auto">
                            Login with {mode.strategy}
                          </span>
                        </span>
                      </div>
                    );
                  }
                })
                : null}
              <p className="my-2">
                <span className="text-fireai-blue" onClick={() => setMethod("login")}>
                  {"login"}
                </span>
                <span className="text-fireai-blue" onClick={() => setMethod("signup")}>
                  {" / signup "}
                </span>
                with username & password
              </p>
            </div>
            :
            method === "login" ?
              <div className="my-2 py-2">
                <form onSubmit={handleLogin} className="w-10/12 mx-auto">
                  <div className="flex flex-col gap-3 justify-center">
                    <input
                      type="email"
                      className="w-full border border-black rounded-md p-2"
                      placeholder="User ID"
                      value={creds.userId}
                      onChange={(e) => setCreds({ ...creds, userId: e.target.value })}
                    />
                    <input
                      type={isPasswordVisible ? "text" : "password"}
                      className="w-full border border-black rounded-md p-2"
                      placeholder="Password"
                      value={creds.password}
                      onMouseEnter={() => setIsPasswordVisible(true)}
                      onMouseLeave={() => setIsPasswordVisible(false)}
                      onChange={(e) => setCreds({ ...creds, password: e.target.value })}
                    />
                    <button
                      type="submit"
                      className="w-full bg-fireai-blue text-white rounded-md p-2"
                    >
                      Login
                    </button>
                  </div>
                </form>
                <p className="my-2 font-thin text-sm">Hover to see password</p>
                <p className="mt-2">login with Google
                  <span className="text-fireai-blue" onClick={() => setMethod("social")}>
                    {" click here"}
                  </span>
                </p>
                <p>for Signup <span className="text-fireai-blue" onClick={() => setMethod("signup")}>{" click here"}</span></p>
              </div>
              :
              <div className="my-2 py-2">
                <form onSubmit={handleLogin} className="w-10/12 mx-auto">
                  <div className="flex flex-col gap-3 justify-center">
                    <input
                      type="text"
                      className="w-full border border-black rounded-md p-2"
                      placeholder="Name"
                      value={creds.name}
                      onChange={(e) => setCreds({ ...creds, name: e.target.value })}
                    />
                    <input
                      type="email"
                      className="w-full border border-black rounded-md p-2"
                      placeholder="User ID"
                      value={creds.userId}
                      onChange={(e) => setCreds({ ...creds, userId: e.target.value })}
                    />
                    <input
                      type={isPasswordVisible ? "text" : "password"}
                      className="w-full border border-black rounded-md p-2"
                      placeholder="Password"
                      value={creds.password}
                      onMouseEnter={() => setIsPasswordVisible(true)}
                      onMouseLeave={() => setIsPasswordVisible(false)}
                      onChange={(e) => setCreds({ ...creds, password: e.target.value })}
                    />
                    <button
                      type="submit"
                      className="w-full bg-fireai-blue text-white rounded-md p-2"
                    >
                      Login
                    </button>
                  </div>
                </form>
                <p className="my-2 font-thin text-sm">Hover to see password</p>
                <p className="mt-2">login with Google
                  <span className="text-fireai-blue" onClick={() => setMethod("social")}>
                    {" click here"}
                  </span>
                </p>
                <p>for login<span className="text-fireai-blue" onClick={() => setMethod("login")}>{" click here"}</span></p>
              </div>
          }
        </div>
        <div className="mt-7"></div>
      </div>
    </div>
  );
}
export default Index;




