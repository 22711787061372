import createGlobe, { COBEOptions } from "cobe";
import { useEffect, useRef, RefObject } from "react";

interface GlobeState {
    phi: number;
    width: number;
    height: number;
}

export function CobeItem() {
    const canvasRef: RefObject<HTMLCanvasElement> = useRef(null);
    useEffect(() => {
        let phi = 0;
        let width = 0;
        const onResize = () => {
            if (canvasRef.current) {
                width = canvasRef.current.offsetWidth;
            }
        };
        window.addEventListener('resize', onResize);
        onResize();

        if (canvasRef.current) {
            const globe = createGlobe(canvasRef.current, {
                devicePixelRatio: 2,
                width: width * 2,
                height: width * 2,
                phi: 0,
                theta: 0.3,
                dark: 1,
                diffuse: 3,
                mapSamples: 16000,
                mapBrightness: 1.2,
                baseColor: [1, 1, 1],
                markerColor: [251 / 255, 100 / 255, 21 / 255],
                glowColor: [1.2, 1.2, 1.2],
                markers: [],
                onRender: (state: GlobeState) => {
                    // Called on every animation frame.
                    // `state` will be an empty object, return updated params.
                    state.phi = phi;
                    phi += 0.005;
                    state.width = width * 2;
                    state.height = width * 2;
                },
            } as unknown as COBEOptions);

            setTimeout(() => {
                if (canvasRef.current) {
                    canvasRef.current.style.opacity = '1';
                }
            });

            return () => {
                globe.destroy();
                window.removeEventListener('resize', onResize);
            };
        }
    }, []);

    return (
        <div className="w-full max-w-[900px] aspect-[1/1] mx-auto mt-auto relative">
            <canvas
                ref={canvasRef}
                className="w-full h-full contain-layout contain-paint contain-size opacity-0 transition-opacity duration-1000 ease"
            />
        </div>
    );
}

export default CobeItem