import React, { useEffect } from "react"
import ModalLayout from "@layouts/modalsLayout"
import { RightArrow } from "@assets/index"
import CheckIfMicrophoneAccessAlreadyPermissionGiven from "@functions/general/checkIfMicPermissionAlreadyGiven"
import { useAppDispatch } from "@store/store"
import { setListeningActiveState } from "@store/features/audio/audioInput"
import { setModalState } from "@store/features/modals/modalsRootSlice"

const MicrophoneAccessModal: React.FC<{ modalName: string }> = ({ modalName }) => {
    const dispatch = useAppDispatch()
    useEffect(() => {
        CheckIfMicrophoneAccessAlreadyPermissionGiven()
    })

    const GetMicrophoneAccessPermission = async () => {
        const permission = await navigator.mediaDevices.getUserMedia({ audio: true })
        if (permission) {
            dispatch(setListeningActiveState({ status: true }))
            dispatch(setModalState({ modalName: modalName, state: false }))
            dispatch(setModalState({ modalName: 'preferredLanguageInfo', state: true }))
        } else {
            dispatch(setListeningActiveState({ status: false }))
        }
    }

    return (
        <div className="absolute h-screen w-screen bg-[#00000080] z-50 flex flex-col justify-center">
            <ModalLayout modalIdentifier={modalName}>
                <>
                    <h1 className="text-3xl pb-2 font-semibold">Microphone Access</h1>
                    <p>Make sure you have a high quality microphone connected to your PC (if you have a built in microphone it might be good enough)</p>
                    <button type="button" className="flex flex-row bg-fireai-blue text-white rounded-full px-4 py-1 mt-5 text-sm" onClick={() => GetMicrophoneAccessPermission()}>
                        <span className="pr-2 my-auto">Allow Microphone Access</span>
                        <img alt="Arrow showing right arrow" src={RightArrow} className="h-1/2 w-5 my-auto" />
                    </button>
                </>
            </ModalLayout>
        </div>
    )
}

export default MicrophoneAccessModal