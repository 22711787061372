import { topBarOptionsTypes } from "@interfaces/components/common/topbar"

const TopbarOptions: topBarOptionsTypes[] = [
    {
        itemName: "LOGOUT",
        location: "/logout"
    },
    {
        itemName: "PRODUCT DEMO",
        location: "/demo"
    }
]

export { TopbarOptions }