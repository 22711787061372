import { FaMoon } from "react-icons/fa";
import { IoSunny } from "react-icons/io5";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@store/store";
import { toggleTheme } from "@store/features/thememode/themeSlice";

const ThemeToggleBtn = () => {
  const themeMode = useAppSelector((state) => state.theme.themeMode);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation(); // Prevent click event from propagating
    dispatch(toggleTheme({ themeMode: themeMode === "light" ? "dark" : "light" }));
  };

  useEffect(() => {
    if (themeMode) {
      document.querySelector("html")?.classList.remove("dark", "light", "null");
      document.querySelector("html")?.classList.add(themeMode);
      localStorage.setItem("theme", themeMode);
    }
  }, [themeMode]);

  useEffect(() => {
    function getThemeFromLocalStorage(): "light" | "dark" | null {
      const theme = localStorage.getItem("theme");
      return theme === "light" || theme === "dark" ? theme : null;
    }

    const localTheme = getThemeFromLocalStorage();
    if (localTheme) {
      dispatch(toggleTheme({ themeMode: localTheme }));
    } else {
      dispatch(toggleTheme({ themeMode: "light" }));
    }
    setLoading(false);
  }, [dispatch]);

  return !loading && (
    <label htmlFor="toggle" className="flex items-center cursor-pointer" onClick={(e) => e.stopPropagation()}>
      <div className="relative">
        <input
          type="checkbox"
          id="toggle"
          className="sr-only"
          checked={themeMode === "light"}
          onChange={handleToggle}
        />
        <div className={`block w-8 h-[13px] rounded-full ${themeMode === "light" ? "bg-blue-400" : "bg-gray-700"}`}></div>
        <div
          className={`absolute -left-1 -top-[3px] w-5 h-5 rounded-full transition-transform ${themeMode === "dark" ? "transform translate-x-full bg-black" : "bg-blue-700"}`}
        >
          <FaMoon className={`w-[11px] h-[11px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white ${themeMode === "dark" ? '' : 'hidden'}`} />
          <IoSunny className={`w-[15px] h-[15px] absolute top-1/2 left-[49%] transform -translate-x-1/2 -translate-y-1/2 text-white font-extrabold ${themeMode === "dark" ? 'hidden' : ''}`} />
        </div>
      </div>
    </label>
  );
};

export default ThemeToggleBtn;
