import { useAppDispatch, useAppSelector } from "@store/store"
import { changeSearchbarValue } from "@store/features/searchBar/SearchbarSlice"
import { Skeleton } from "antd"


const SuggestiveBoxes: React.FC = () => {


    const suggestiveTexts = useAppSelector((state) => state.databaseInstance.suggestiveTexts)
    const promptTexts = suggestiveTexts.slice(0, 4)
    const dispatch = useAppDispatch()


    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            {(promptTexts.length > 0) ? promptTexts.map((item, index) => {
                return (
                    <div
                        key={index}
                        className="border-fireai-blue dark:bg-black border-[1px] flex flex-row justify-center text-gray-900 dark:text-white w-full min-w-fit h-full text-left mx-auto my-auto p-2 rounded-xl cursor-pointer"
                        onClick={() => dispatch(changeSearchbarValue({ value: item.descriptiveText }))}
                    >
                        <div className="text-xs md:text-sm lg:text-base mx-auto w-11/12 h-14 flex flex-col justify-center">
                            <span className="leading-1 line-clamp-2">{item.descriptiveText}</span>
                        </div>
                    </div>
                )
            }) : <>
                {Array.from({ length: 4 }, (_, index) => (
                    <Skeleton.Input active size="default" block={false} key={index} style={{ width: "100%", height: "70px" }} />
                ))}
            </>}
        </div>
    )
}

export default SuggestiveBoxes