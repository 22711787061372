import { getAxiosClient } from '@functions/api/axiosInstance'
import { SidebarBottomTypes, SidebarDataTypes } from '@interfaces/components/common/sidebar'
import { AxiosResponse } from 'axios'
import ReactGA from 'react-ga4'

type SidebarResponseType = {
    sidebarMain: SidebarDataTypes[]
    sideBarBottom: SidebarBottomTypes[]
}

const FetchSidebarData = async (): Promise<SidebarResponseType | null> => {
    try {
        const _axios = await getAxiosClient()
        const SidebarResponse: AxiosResponse<SidebarResponseType> = await _axios.get<SidebarResponseType>("/api/sidebar/data")
        ReactGA.event({ category: "API Request", action: "Fetch sidebar data", label: `Success in ${SidebarResponse.headers['responseTime'] / 1000}s` });
        return SidebarResponse.data
    } catch (error) {
        console.log("error while fetching sidebar data", error);
        ReactGA.event({ category: "API Request", action: "Fetch sidebar data", label: "Failure" });
        return null;
    }
}

export { FetchSidebarData }