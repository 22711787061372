import postFeedback from '@api/dashboard/postFeedback';
import { useAppSelector } from '@store/store';
import { useState } from 'react';
import { FaThumbsDown, FaThumbsUp } from 'react-icons/fa'

const FeedBack = ({ sqlQuery }: { sqlQuery: string }) => {
    const [postingFeedback, setPostingFeedback] = useState(false);
    const [responseReceived, setResponseReceived] = useState<string | null>(null);
    const question = useAppSelector((state) => state.searchbar.value)
    const db = useAppSelector((state) => state.databaseInstance.id)

    const handleClick = async (was_satisfied: boolean) => {
        // console.log('Feedback submitted')
        setPostingFeedback(true);
        const response = await postFeedback({
            was_satisfied,
            question,
            sql_query: sqlQuery,
            database_instance: db
        });
        setResponseReceived(response);
    }

    return !postingFeedback && !responseReceived ? (
        <div className='w-full col-span-4 sm:col-span-10 sm:col-start-2 mx-auto px-[3%] py-[1%] my-4 text-center text-xl text-[#6A7684]'>
            <p>Was is it helpful?</p>
            <div className='flex justify-center items-center gap-2 my-4'>

                <div
                    className='mx-2 flex gap-2 justify-center items-center border-2 p-3 thumbs-bg-hover thumbs-text-hover dark:border-[#0075FF] rounded-xl'
                    onClick={() => handleClick(true)}>

                    <FaThumbsUp /> <span className='text-xl'>Yes</span>
                </div>
                <div
                    className='mx-2 flex gap-2 justify-center items-center border-2 p-3 thumbs-bg-hover thumbs-text-hover dark:border-[#0075FF] rounded-xl'
                    onClick={() => handleClick(false)}>
                    <FaThumbsDown className='' /> <span className='text-xl'>No</span>
                </div>
            </div>
        </div>
    ) : responseReceived !== "error while posting feedback" && (
        <div className='w-full col-span-4 sm:col-span-10 sm:col-start-2 mx-auto px-[3%] py-[1%] my-4 text-center text-xl text-[#6A7684]'>
            <p>{responseReceived}</p>
        </div>
    )
}

export default FeedBack
