import React from "react"
import ModalLayout from "@layouts/modalsLayout"
import { RightArrow } from "@assets/index"
import { setAccessState } from "@store/features/audio/microphoneAcessSlice"
import { useAppSelector } from "@store/store"
import getLanguageNameFromCode from "@functions/general/getLanguageNameFromCode"
import { useAppDispatch } from "@store/store";
import { setModalState } from "@store/features/modals/modalsRootSlice";
import { setListeningActiveState } from "@store/features/audio/audioInput"

const PreferredLanguageInfoModal: React.FC<{ modalName: string }> = ({ modalName }) => {
    const dispatch = useAppDispatch()
    const user = useAppSelector(state => state.userDetails)

    const getMicrophoneAccessPermission = async () => {
        const permission = await navigator.mediaDevices.getUserMedia({ audio: true })
        if (permission) {
            dispatch(setAccessState({ access: true }))
            dispatch(setListeningActiveState({ status: false }))
        } else {
            console.log("Microphone Permission Denied")

            dispatch(setListeningActiveState({ status: false }))
        }
    }

    const pickLanguageAction = () => {
        getMicrophoneAccessPermission()
        dispatch(setModalState({ modalName: "PreferredLanguageSelection", state: true }))
        dispatch(setModalState({ modalName: modalName, state: false }))
    }

    const stayWithSelectionAction = () => {
        dispatch(setModalState({ modalName: modalName, state: false }))
        dispatch(setListeningActiveState({ status: true }))
    }

    return (
        <div className="absolute h-screen w-screen bg-[#00000080] z-50 flex flex-col justify-center">
            <ModalLayout modalIdentifier={modalName}>
                <>
                    <h1 className="text-3xl pb-2 font-semibold">Pick Speech Language</h1>
                    <p>You can ask your queries in any language. It supports around 25 Indian regional languages Note - English is set default language.</p>
                    <div className="flex flex-row gap-2">
                        <button type="button" className="flex flex-row bg-fireai-blue text-white rounded-full px-4 py-1 mt-5 text-sm" onClick={pickLanguageAction}>
                            <span className="pr-2 my-auto">Pick Language</span>
                            <img alt="Arrow showing right arrow" src={RightArrow} className="h-1/2 w-5 my-auto" />
                        </button>
                        <button type="button" className="flex flex-row bg-fireai-blue text-white rounded-full px-4 py-1 mt-5 text-sm" onClick={stayWithSelectionAction}>
                            <span className="pr-2 my-auto">Continue with {getLanguageNameFromCode(user?.preferredLanguage)}</span>
                            <img alt="Arrow showing right arrow" src={RightArrow} className="h-1/2 w-5 my-auto" />
                        </button>
                    </div>
                </>
            </ModalLayout>
        </div>
    )
}

export default PreferredLanguageInfoModal