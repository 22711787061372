// import { RiBarChart2Fill, RiCalendarScheduleLine, RiTableView, RiDownload2Line } from "react-icons/ri";
import { RiDownload2Line } from "react-icons/ri";
// import { FaFilePdf, FaFileExcel } from "react-icons/fa6";
import { Toolbar } from "@interfaces/components/dashboard/toolbar";
import { utils, writeFile } from "xlsx";

const getFormattedDate = () => {
  const date = new Date();
  const day = date.getDate().toString().padStart(2, '0'); // Add leading zero if needed
  const month = date.toLocaleString('default', { month: 'long' }); // Get full month name
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
};

const QueryToolbarFilterOptions: Toolbar[] = [
  // {
  //     itemIcon: <RiTableView />,
  //     itemLabel: "Table View"
  // },
  // {
  //     itemIcon: <RiBarChart2Fill />,
  //     itemLabel: "Chart View"
  // }
];

const ToolbarLeftOptions: Toolbar[] = [
  // {
  //     itemIcon: <RiCalendarScheduleLine />,
  //     itemLabel: "Schedule Report"        
  // },
  {
    itemIcon: <RiDownload2Line />,
    itemLabel: "Download",
    onClick: (data: { [key: string]: unknown }[], reportName: string) => {
      const workBook = utils.book_new();
      const workSheet = utils.json_to_sheet(data);
      utils.book_append_sheet(workBook, workSheet, 'Sheet1');

      writeFile(workBook, `${reportName} (${getFormattedDate()}).xlsx`);
    },
  },
];

const ToolbarRightOptions: Toolbar[] = [
  // {
  //     itemIcon: <FaFilePdf />,
  //     itemLabel: "Download PDF"
  // },
  // {
  //     itemIcon: <FaFileExcel />,
  //     itemLabel: "Download CSV"
  // }
];

export { ToolbarLeftOptions, ToolbarRightOptions, QueryToolbarFilterOptions };
