/* eslint-disable @typescript-eslint/no-unused-vars */
import { useAppSelector } from "@store/store";
import { ApexOptions } from "apexcharts";
import React from "react";
import ReactApexChart from "react-apexcharts";

interface GraphData {
  x: (string | number)[];
  series: {
    name: string;
    data: { value: number; name: string }[];
  }[];
  xlabel?: string;
  ylabel?: string;
  barLabelName?: string;
  graphTitle?: string;
  graphType?: string;
}

interface StackedBarProps {
  graphdata: GraphData;
}

const StackedBar: React.FC<StackedBarProps> = ({ graphdata }) => {
  console.log(graphdata);
  const { x, series } = graphdata;
  const themeMode = useAppSelector((state) => state.theme.themeMode);
  const hqNames = Array.from(new Set(series.flatMap(s => s.data.map(d => d.name))));

  // Transform the series data to match the stacked format
  const transformedSeries = hqNames.map(hq => ({
    name: hq,
    data: series.map(s => {
      const dataPoint = s.data.find(d => d.name === hq);
      return dataPoint ? dataPoint.value : 0;
    }),
  }));

  const chartOptions: ApexOptions = {
    chart: {
      type: "bar",
      height: "auto",
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          total: {
            enabled: false,
            offsetX: 0,
            style: {
              fontSize: "12px",
              fontWeight: 10,
            },
            formatter: function (val) {
              return Number(val).toFixed(2);
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: true,  // Enable data labels globally
      formatter: function (val) {
        return Number(val).toFixed(2);
      },
      style: {
        colors: themeMode === 'dark' ? ['#000'] : ['#fff'],  // Color for the labels
      },
    },
    stroke: {
      width: 0.5,
      colors: ["#fff"],
    },
    theme: {
      mode: themeMode === "light" ? "light" : "dark",
    },
    title: {
      text: graphdata?.graphTitle,
      style: {
        fontSize: "16px",
      },
      align: "center",
    },
    xaxis: {
      categories: x,
      title: {
        text: graphdata?.xlabel,
        style: {
          fontSize: "14px",
        },
      },
    },
    yaxis: {
      title: {
        text: graphdata?.ylabel,
        style: {
          fontSize: "14px",
        },
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "left",
      offsetX: 0,
      fontSize: '10px',
    },
  };

  return (
    <div>
      <div id="chart" className="w-full h-[600px] border-2 border-[#CACACA] rounded-lg my-2 p-2">
        <ReactApexChart
          options={chartOptions}
          series={transformedSeries}
          type="bar"
          height="100%"
        />
      </div>
    </div>
  );
};

export default StackedBar;
