import { PayloadAction, createSlice } from "@reduxjs/toolkit"


const initialState = {
    filters: {}
}

const filterSlice = createSlice({
    name: "filters",
    initialState,
    reducers: {
        changeFilter: (state, action: PayloadAction<{ key: string, value: string }>) => {
            state.filters[action.payload.key] = action.payload.value
        },
        clearFilter: (state, action: PayloadAction<{ key: string }>) => {
            delete state.filters[action.payload.key]
        }
    }
})

export default filterSlice.reducer;
export const { changeFilter, clearFilter } = filterSlice.actions;