import { ConfigProvider, Input, theme, Form } from "antd"
import { useAppDispatch, useAppSelector } from "@store/store"
import { changeSearchbarValue } from "@store/features/searchBar/SearchbarSlice"
import { setModalState } from "@store/features/modals/modalsRootSlice"
import { setListeningActiveState } from "@store/features/audio/audioInput"
import { FaMicrophone, FaPaperPlane } from "react-icons/fa";
import { FetchPromptResponse } from "@api/dashboard/getPromptResponse"
import { changeQueryResponse, changeQueryLoadingState } from "@store/features/reports/queryResponse";
import { setMessage } from "@store/features/message/messageSlice";
import ReactGA from 'react-ga4'
import SearchBarMicInput from "./SearchBarMicInput";
import CheckIfMicrophoneAccessAlreadyPermissionGiven from "@functions/general/checkIfMicPermissionAlreadyGiven"
import { useEffect } from "react";

const SearchBar = () => {
    const dispatch = useAppDispatch()
    const inputValue = useAppSelector((state) => state.searchbar.value)
    const db_instance = useAppSelector((state) => state.databaseInstance.id)
    const themeMode = useAppSelector((state) => state.theme.themeMode)
    const audioInputStatus = useAppSelector((state) => state.audioInputState.active)
    const inputAudioAccess = useAppSelector(state => state.audioAccess.access)

    useEffect(() => {
        CheckIfMicrophoneAccessAlreadyPermissionGiven()
    }, [])

    const darkModeColors = {
        colorBorder: "#1B41E9",
        // colorBgBase: "#2A2828",
    }
    const lightModeColors = {
        colorBorder: "#d9d9d9",
        colorBgBase: "#ffffff",
    }

    const SearchAction = async () => {
        dispatch(changeQueryResponse({ value: null }))
        dispatch(changeQueryLoadingState({ value: true }))
        ReactGA.event({ category: "User interaction", action: "Query - search", label: inputValue });
        if (inputValue.length > 10) {
            const response = await FetchPromptResponse(db_instance, inputValue)
            if (response != null || response != undefined) {
                dispatch(changeQueryResponse({ value: response }))
                dispatch(changeQueryLoadingState({ value: false }))
            } else {
                dispatch(changeQueryLoadingState({ value: false }))
            }
        } else {
            dispatch(changeQueryLoadingState({ value: false }))
            dispatch(setMessage({ type: "error", content: "Prompt should be greater than 10 characters", duration: 5 }))
        }
    }

    const microphoneAccess = async () => {
        // @ts-expect-error Microphone permission is included in browser typespace
        const permissionStatus = await navigator.permissions.query({ name: 'microphone' });
        if (permissionStatus.state === 'granted') {
            dispatch(setListeningActiveState({ status: true }))
        } else {
            dispatch(setModalState({ modalName: "bhashiniMicrophoneAccess", state: true }))
        }
    }

    return (
        <>
            <div className="relative">
                <ConfigProvider
                    theme={{
                        algorithm: themeMode === "light" ? theme.defaultAlgorithm : theme.darkAlgorithm,
                        token: themeMode === "light" ? lightModeColors : darkModeColors,
                    }}
                >
                    <div className="relative">
                        {inputAudioAccess && audioInputStatus && <SearchBarMicInput />}
                        <Form onKeyDown={(e) => {
                            if (e.key == "Enter" && e.ctrlKey) SearchAction()
                        }
                        }>
                            <Input.TextArea data-gramm="false" maxLength={4000} placeholder="What can I help you today with?" allowClear={false} rows={4} className="border-fireai-blue border-[1px] rounded-lg"
                                onChange={input => {
                                    dispatch(changeSearchbarValue({ value: input.target.value }))
                                }}
                                value={inputValue ? inputValue : ""}
                            />
                        </Form>
                    </div>
                    <div className="absolute bottom-2 right-12 z-20 font-thin text-sm">
                        {inputValue.length}/1000
                    </div>
                    <div className="absolute bottom-3 right-5 z-20">
                        <FaPaperPlane className="text-fireai-blue cursor-pointer hover:text-black" onClick={() => SearchAction()} />
                    </div>
                    <div className="absolute top-3 right-5 z-20">
                        <FaMicrophone className="text-fireai-blue cursor-pointer hover:text-black" onClick={microphoneAccess} />
                    </div>
                </ConfigProvider>
            </div>
            <div className="text-right">
                <span className="text-xs text-gray-500">Ctrl + Enter to submit</span>
            </div>
        </>
    )
}

export default SearchBar