import { getAxiosClient } from '@functions/api/axiosInstance'
import { AxiosResponse } from 'axios'
import ReactGA from 'react-ga4'
import { PreferredLanguageListType } from '@interfaces/api/bhashini'

const FetchPreferredLanguageList = async (): Promise<PreferredLanguageListType[] | null> => {
    try {
        const _axios = await getAxiosClient()
        const PreferredLanguageList: AxiosResponse<PreferredLanguageListType[]> = await _axios.get<PreferredLanguageListType[]>("/api/bhashini/config")
        ReactGA.event({ category: "API Request", action: "Fetch preferred language list", label: `Success in ${PreferredLanguageList.headers['responseTime'] / 1000}s` });
        return PreferredLanguageList.data
    } catch (error) {
        console.log("error while fetching sidebar data", error);
        ReactGA.event({ category: "API Request", action: "Fetch preferred language list", label: "Failure" });
        return null;
    }
}

export { FetchPreferredLanguageList }