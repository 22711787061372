import { DonutChart } from '@components/tremor/DonutChart';
import NewBarChart from '@components/tremor/NewBarChart';
import StackedBar from '@components/tremor/stackedBar';
import { GraphData } from '@interfaces/components/reports/page'
import React from 'react'

const GraphComponent: React.FC<{ type: string, data: GraphData, legendPosition?: 'bottom' | 'top' | 'right' | 'left' }> = ({ type, data, legendPosition = 'bottom' }) => {

    const pieData: { x: string, y: number }[] = []
    const cleanData = (data: GraphData) => {
        const thresholdPercentage = 1.5; // Define a percentage threshold for small values (e.g., 5%)

        if (!data.y || !data.x) {
            console.error("Missing data.x or data.y in the input data");
            return; // Exit if data is missing
        }

        let totalSum = 0;

        // Calculate the total sum of y-values
        for (let i = 0; i < data.y.length; i++) {
            totalSum += Number(data.y[i]);
        }

        const thresholdValue = (totalSum * thresholdPercentage) / 100;  // Values below this will go into "Others"
        let othersSum = 0;
        let hasOthers = false;

        // Iterate through y-values to group small values into "Others"
        for (let i = 0; i < data.y.length; i++) {
            const yValue = Number(data.y[i]);

            if (yValue !== 0) {  // Skip zero values
                const obj = {
                    x: data.x[i] as string,
                    y: yValue
                };

                // Check if the value is below the threshold
                if (yValue < thresholdValue) {
                    othersSum += yValue;  // Add small values to the "Others" sum
                    hasOthers = true;     // Set flag to true if there are values to group
                } else {
                    pieData.push(obj);  // Only push values above the threshold
                }
            }
        }

        // Add the "Others" entry if necessary
        if (hasOthers) {
            pieData.push({
                x: "Others",
                y: othersSum // Add the total of small values in the "Others" section
            });
        }
    };
    switch (type) {
        case 'bar':
            return <NewBarChart graphdata={data} />;
        case 'stackedBar':
            return <StackedBar graphdata={{ ...data, x: data.x, series: data.series || [] }} />;
        case 'pie':
            cleanData(data);
            return <div className="flex flex-col items-center justify-center w-6/12 mx-auto py-5">
                <DonutChart data={pieData as never[]} category="x" variant="donut" value="y" onValueChange={undefined} className="bg-black" legendPosition={legendPosition} />
            </div>
        default:
            break;
    }
}

export default GraphComponent
