import { createRootRoute, Outlet } from '@tanstack/react-router'
// import { TanStackRouterDevtools } from '@tanstack/router-devtools'
import BaseLayout from '@layouts/baseLayout'
import ReactGA from 'react-ga4'
import '@styles/index.css'
import * as Sentry from "@sentry/react";
import { useEffect, useRef } from 'react';
import LoadingContainer from "@components/common/PageLoader"
import ModalsRoot from '@components/modals/modalsRoot';


Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["https://cug-middleware.fireai.in", "https://middleware.fireai.in"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: import.meta.env.VITE_SENTRY_ENABLED === "Y" ? true : false
});

export const Route = createRootRoute({
  errorComponent: () => <p>We are facing issues loading this page. Please refresh.</p>,
  component: Root,
  pendingComponent: () => <LoadingContainer />
})

function Root() {
  useEffect(() => {
    if (window.location.pathname != "/") {
      const urlSearchString = window.location.search;
      const params = new URLSearchParams(urlSearchString);
      const userToken = params.get('token')
      params.delete('token')
      sessionStorage.setItem('token', userToken ? userToken : "")
    }
  })

  useRef
  ReactGA.initialize('G-5TKDYM2KJZ')
  return (
    <>
      <ModalsRoot />
      {(window.location.pathname != "/session-expired" && window.location.pathname != "/") ?
        <BaseLayout>
          <Outlet />
        </BaseLayout> : <Outlet />}
      {/* <TanStackRouterDevtools /> */}
    </>
  )
}