import { datePickerOptions } from '@data/reports/datePickerOptions'
import { getDates } from '@functions/reports/dateFormatting'
import { changeDateFilter } from '@store/features/reports/dateFilter'
import { useAppSelector } from '@store/store'
import { ConfigProvider, Select, theme, DatePicker } from 'antd'
import { RangePickerProps } from 'antd/es/date-picker/generatePicker/interface'
import { Dayjs } from 'dayjs'
import { useState } from 'react'
import { useDispatch } from 'react-redux'

const { RangePicker } = DatePicker;

const DatePickerComponent: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
    const themeMode = useAppSelector((state) => state.theme.themeMode)
    const { fromDate, toDate } = useAppSelector((state) => state.dateFilter)
    const dispatch = useDispatch()

    const [datePickerValue, setDatePickerValue] = useState<string>("pick a date range")

    const handleSelectChange = (value: string) => {
        console.log(value)
        setDatePickerValue(value)
        if (value === "pick a date range" || value === "custom") return { fromDate: null, toDate: null }
        const { fromDate, toDate } = getDates({ value });
        dispatch(changeDateFilter({ fromDate, toDate }))
        console.log(fromDate, toDate);
    }

    const handleDateChange: RangePickerProps<Dayjs>['onChange'] = (date) => {
        if (date && date[0] && date[1]) {
            const { fromDate, toDate } = getDates({
                value: "custom",
                myCustomFrom: date[0].toDate(),
                myCustomTo: date[1].toDate()
            });
            dispatch(changeDateFilter({ fromDate, toDate }));
        }
    };

    return (
        <ConfigProvider
            theme={{
                algorithm: themeMode === "light" ? theme.defaultAlgorithm : theme.darkAlgorithm,
            }}
        >
            <Select
                disabled={isLoading}
                className="w-[200px] xs:w-[100px] md:w-fit md:min-w-[150px] border-[1px] border-fireai-blue rounded-lg outline-none"
                onChange={handleSelectChange}
                options={datePickerOptions}
                value={fromDate && toDate ? fromDate + " to " + toDate : datePickerValue}
                allowClear
            />
            {datePickerValue === "custom" &&
                <RangePicker
                    disabled={isLoading}
                    className="w-[200px] xs:w-[100px] md:w-fit md:min-w-[150px] border-[1px] border-fireai-blue rounded-lg outline-none"
                    onChange={handleDateChange}
                    allowEmpty
                />}
        </ConfigProvider>
    )
}

export default DatePickerComponent
