import { useAppSelector } from "@store/store"

const Loader = () => {
    const themeMode = useAppSelector((state) => state.theme.themeMode)
    return (
        <div className="w-full mx-auto inline-flex justify-center items-center my-10">
            <div
                className={`inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid ${themeMode === "light" ? "border-[#1B41E9]" : "border-white"} border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]`}
                role="status">
                <span
                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                >Loading...</span>
            </div>
        </div>
    )
}

export default Loader
