import { createFileRoute } from '@tanstack/react-router'
import ReactGA from 'react-ga4'

export const Route = createFileRoute('/reports/')({
  component: Reports,
  // beforeLoad: ({ context }) => {
  //   if (!context.isAuthenticated) {
  //     throw redirect({
  //       to: '/',
  //     })
  //   }
  // },
})

function Reports() {
  ReactGA.send({ hitType: "pageview", page: `${window.location.pathname + window.location.search}`, title: "Reports home page" });
  return (
    <p>Hello reports</p>
  )
}


export default Reports