const layout = {
    1: [
        "lg:col-span-1"
    ],
    2: [
        "lg:col-span-1",
        "lg:col-span-1"
    ],
    3: [
        "lg:col-span-1",
        "lg:col-span-1",
        "lg:col-span-1"
    ],
    4: [
        "lg:col-span-1",
        "lg:col-span-1",
        "lg:col-span-1",
        "lg:col-span-1"
    ],
    5: [
        "lg:col-span-1",
        "lg:col-span-1",
        "lg:col-span-1",
        "lg:col-span-1",
        "lg:col-span-1"
    ],
    6: [
        "lg:col-span-1 lg:row-span-2",
        "lg:col-span-1 lg:row-span-1",
        "lg:col-span-1 lg:row-span-1",
        "lg:col-span-1 lg:row-span-2",
        "lg:col-span-1 lg:row-span-2",
        "lg:col-span-1 lg:row-span-2"
    ],
    7: [
        "lg:col-span-1 lg:row-span-2",
        "lg:col-span-1 lg:row-span-1",
        "lg:col-span-1 lg:row-span-1",
        "lg:col-span-1 lg:row-span-1",
        "lg:col-span-1 lg:row-span-1",
        "lg:col-span-1 lg:row-span-2",
        "lg:col-span-1 lg:row-span-2"
    ],
    8: [
        "lg:col-span-1 lg:row-span-2",
        "lg:col-span-1 lg:row-span-1",
        "lg:col-span-1 lg:row-span-1",
        "lg:col-span-1 lg:row-span-1",
        "lg:col-span-1 lg:row-span-1",
        "lg:col-span-1 lg:row-span-1",
        "lg:col-span-1 lg:row-span-1",
        "lg:col-span-1 lg:row-span-2"
    ],
    9: [
        "lg:col-span-1 lg:row-span-2",
        "lg:col-span-1 lg:row-span-1",
        "lg:col-span-1 lg:row-span-1",
        "lg:col-span-1 lg:row-span-1",
        "lg:col-span-1 lg:row-span-1",
        "lg:col-span-1 lg:row-span-1",
        "lg:col-span-1 lg:row-span-1",
        "lg:col-span-1 lg:row-span-1",
        "lg:col-span-1 lg:row-span-1"
    ],
    10: [
        "lg:col-span-1 lg:row-span-1",
        "lg:col-span-1 lg:row-span-1",
        "lg:col-span-1 lg:row-span-1",
        "lg:col-span-1 lg:row-span-1",
        "lg:col-span-1 lg:row-span-1",
        "lg:col-span-1 lg:row-span-1",
        "lg:col-span-1 lg:row-span-1",
        "lg:col-span-1 lg:row-span-1",
        "lg:col-span-1 lg:row-span-1",
        "lg:col-span-1 lg:row-span-1"
    ]
}

const layoutGenerator = (count: number): string[] => {
    return layout[count]
}

export default layoutGenerator