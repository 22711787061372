import React, { useState } from 'react';
import { ContactUsModalProps } from '@interfaces/components/common/topbar';

const ContactUsModal: React.FC<ContactUsModalProps> = ({ isOpen, onClose }) => {
    const [formDetails, setFormDetails] = useState({
        name: '',
        contact: '',
        email: '',
    });
    const handleContactSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (formDetails.name === '' || formDetails.contact === '' || formDetails.email === '') {
            alert('Please fill all the fields');
            return;
        }

        const formData = new FormData();
        formData.append('name', formDetails.name);
        formData.append('phone', formDetails.contact);
        formData.append('email', formDetails.email);
        try {
            await fetch('https://formcarry.com/s/zBZlhCp4MJg', {
                method: 'POST',
                body: formData,
                headers: {
                    'Accept': 'application/json',
                },
            });

            alert('Thank you for contacting us. We will get back to you soon.');
            setFormDetails({
                name: '',
                contact: '',
                email: '',
            });
        } catch (error) {
            console.error('Error:', error);
        }

        onClose();
    };


    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black z-50 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white dark:bg-dark-theme-grey w-full max-w-md mx-auto p-6 rounded-lg shadow-lg">
                <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-[#DADADA]">Request Product Demo</h2>
                <form onSubmit={handleContactSubmit}>
                    <div className="mb-4">
                        <label className="block">Name</label>
                        <input
                            type="text"
                            className="w-full px-3 py-2 border placeholder:font-normal rounded-lg dark:bg-[#3E3E3E] dark:border-black outline-blue-600"
                            value={formDetails.name}
                            onChange={(e) => setFormDetails({ ...formDetails, name: e.target.value })}
                            placeholder='Your Name'
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block">Contact</label>
                        <input
                            type="text"
                            className="w-full px-3 py-2 border placeholder:font-normal rounded-lg dark:bg-[#3E3E3E] dark:border-black outline-blue-600"
                            value={formDetails.contact}
                            onChange={(e) => setFormDetails({ ...formDetails, contact: e.target.value })}
                            placeholder='Your Contact Number'
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block">Email</label>
                        <input
                            type="email"
                            className="w-full px-3 py-2 border placeholder:font-normal rounded-lg dark:bg-[#3E3E3E] dark:border-black outline-blue-600"
                            value={formDetails.email}
                            onChange={(e) => setFormDetails({ ...formDetails, email: e.target.value })}
                            placeholder='Your Email ID'
                        />
                    </div>
                    {/* <div className="mb-4">
                        <label className="block">Description / Query</label>
                        <textarea
                            className="w-full px-3 py-2 border placeholder:font-normal rounded-lg dark:bg-[#3E3E3E] dark:border-black outline-blue-600"
                            value={formDetails.description}
                            onChange={(e) => setFormDetails({ ...formDetails, description: e.target.value })}
                            placeholder='Enter your message here...'
                            rows={4}
                        ></textarea>
                    </div> */}
                    <div className="flex justify-end">
                        <button
                            type="button"
                            className="bg-fireai-grey text-black px-4 py-2 rounded-lg mr-2"
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                        <button type="submit" className="bg-blue-600 text-white px-4 py-2 rounded-lg">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ContactUsModal;
