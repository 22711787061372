import { RouterProvider } from '@tanstack/react-router'
import { router } from './router'
function InnerApp() {
  return <RouterProvider router={router} />
}

// Render the app
export default function App() {
  return (
    <>
      <InnerApp />
    </>
  )
}
