import React from "react";
import { ConfigProvider, theme } from "antd";
import { DonutChart } from "@components/tremor/DonutChart";
import BarChart from "@components/tremor/BarChart";
import { useAppSelector } from "@store/store";

const Chart: React.FC<{ type: string; data: never[] }> = ({ type, data }) => {
  const themeMode = useAppSelector((state) => state.theme.themeMode);

  const darkModeColors = {
    colorBorder: "#1B41E9",
    colorBgBase: "#2A2828",
  };
  const lightModeColors = {
    colorBorder: "#d9d9d9",
    colorBgBase: "#ffffff",
  };

  console.log("RETURNING FOR: ", type);

  switch (type) {
    case "pie":
      return (
        <ConfigProvider
          theme={{
            algorithm: themeMode === "light" ? theme.defaultAlgorithm : theme.darkAlgorithm,
            token: themeMode === "light" ? lightModeColors : darkModeColors,
          }}
        >
          <div className="flex flex-col items-center justify-center w-10/12 mx-auto">
            <DonutChart data={data} category="x" variant="donut" value="y" valueFormatter={(number: number) => `${number.toFixed(2)}`} onValueChange={undefined} className="bg-black" />
          </div>
        </ConfigProvider>
      );
    case "bar":
      return (
        <ConfigProvider
          theme={{
            algorithm: themeMode === "light" ? theme.defaultAlgorithm : theme.darkAlgorithm,
            token: themeMode === "light" ? lightModeColors : darkModeColors,
          }}
        >
          <BarChart className="h-80 ml- justify-end" data={data} categories={["x"]} />

        </ConfigProvider>
      );
    default:
      return null;
  }
};

export default Chart;
