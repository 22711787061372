import React, { useState, useMemo, useEffect, useCallback } from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { ConfigProvider, theme, Select, Slider, Button } from "antd";
import { useAppSelector } from "@store/store";

interface DataItem {
  [key: string]: number | string | null;
}

interface Column {
  key: string;
  dataIndex: string;
  title: string;
}

const MAX_DATA_POINTS = 1000;

export default function QueryVisualizer(
  { tableData }: any

  // tableData: { columns: Column[]; data: DataItem[] };
) {
  // console.log(tableData?.data[0]);
  const [selectedColumn, setSelectedColumn] = useState<string>("");
  const [topPercentage, setTopPercentage] = useState<number>(30);
  const [chartType, setChartType] = useState<"donut" | "bar">("donut");
  const [barCount, setBarCount] = useState<number>(10);
  const [sortAscending, setSortAscending] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const themeMode = useAppSelector((state) => state.theme.themeMode || "light");

  const darkModeColors = {
    colorBorder: "#1B41E9",
    colorBgBase: "#2A2828",
    colorText: "#ffffff",
  };
  const lightModeColors = {
    colorBorder: "#d9d9d9",
    colorBgBase: "#ffffff",
    colorText: "#000000",
  };

  useEffect(() => {
    if (tableData && tableData.columns.length > 1) {
      setSelectedColumn(tableData.columns[1].dataIndex);
    }
  }, [tableData]);

  const handleColumnChange = useCallback((value: string) => {
    setSelectedColumn(value);
    setErrorMessage("");
  }, []);

  const handleTopPercentageChange = useCallback((value: number) => {
    setTopPercentage(value);
  }, []);

  const handleChartTypeChange = useCallback((value: "donut" | "bar") => {
    setChartType(value);
  }, []);

  const handleBarCountChange = useCallback((value: number) => {
    setBarCount(value);
  }, []);

  const toggleSortOrder = useCallback(() => {
    setSortAscending((prev) => !prev);
  }, []);

  const getChartData = useMemo(() => {
    if (!tableData || !selectedColumn) return { labels: [], series: [] };

    const isNumeric = (value: any) =>
      value !== null && !isNaN(parseFloat(value)) && isFinite(value);

    let numericData = tableData.data
      .map((item) => ({
        ...item,
        [selectedColumn]:
          item[selectedColumn] === null ? 0 : item[selectedColumn],
      }))
      .filter((item) => isNumeric(item[selectedColumn]));

    if (numericData.length === 0) {
      setErrorMessage(
        "Can't generate graph for this data. Selected column contains non-numeric values."
      );
      return { labels: [], series: [] };
    }

    if (numericData.length > MAX_DATA_POINTS) {
      setErrorMessage(
        `Dataset is too large. Showing top ${MAX_DATA_POINTS} items.`
      );
      numericData = numericData.slice(0, MAX_DATA_POINTS);
    }

    const sortedData = [...numericData].sort((a, b) =>
      sortAscending
        ? Number(a[selectedColumn]) - Number(b[selectedColumn])
        : Number(b[selectedColumn]) - Number(a[selectedColumn])
    );

    if (chartType === "bar") {
      const topItems = sortedData.slice(0, barCount);
      const labels = topItems.map((obj) =>
        String(obj[tableData.columns[0].dataIndex])
      );
      const series = [
        {
          name: tableData.columns.find(
            (col) => col.dataIndex === selectedColumn
          )?.title,
          data: topItems.map((obj) => Number(obj[selectedColumn])),
        },
      ];
      return { labels, series };
    } else {
      const total = sortedData.reduce(
        (acc, obj) => acc + Number(obj[selectedColumn]),
        0
      );
      const threshold = total * (topPercentage / 100);

      let runningSum = 0;
      const topItems: DataItem[] = [];
      const others: DataItem[] = [];

      for (const item of sortedData) {
        if (runningSum < threshold) {
          topItems.push(item);
          runningSum += Number(item[selectedColumn]);
        } else {
          others.push(item);
        }
      }

      const labels = topItems.map((obj) =>
        String(obj[tableData.columns[0].dataIndex])
      );
      const series = topItems.map((obj) => Number(obj[selectedColumn]));

      if (others.length > 0) {
        const othersValue = others.reduce(
          (acc, obj) => acc + Number(obj[selectedColumn]),
          0
        );
        labels.push("Others");
        series.push(othersValue);
      }

      return { labels, series };
    }
  }, [
    tableData,
    selectedColumn,
    topPercentage,
    chartType,
    barCount,
    sortAscending,
  ]);

  const { labels, series } = getChartData;

  const getCommonOptions = (colors: any): ApexOptions => ({
    labels,
    chart: {
      fontFamily: "Inter, sans-serif",
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
      background: colors.colorBgBase,
    },
    theme: {
      mode: themeMode,
      palette: "palette1",
    },
    legend: {
      position: "bottom",
      fontFamily: "Inter, sans-serif",
      labels: {
        colors: colors.colorText,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val: number) => Math.round(val).toLocaleString(),
      style: {
        fontSize: "12px",
        fontFamily: "Inter, sans-serif",
        colors: [colors.colorText],
      },
    },
    tooltip: {
      theme: themeMode,
      y: {
        formatter: (val: number) => Math.round(val).toLocaleString(),
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  const getBarOptions = (colors: any): ApexOptions => ({
    ...getCommonOptions(colors),
    chart: {
      ...getCommonOptions(colors).chart,
      type: "bar",
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
      },
    },
  });

  const getDonutOptions = (colors: any): ApexOptions => ({
    ...getCommonOptions(colors),
    chart: {
      ...getCommonOptions(colors).chart,
      type: "donut",
    },
    plotOptions: {
      pie: {
        donut: {
          size: "65%",
          background: "transparent",
          labels: {
            show: true,
            name: {
              show: true,
              fontFamily: "Inter, sans-serif",
              color: colors.colorText,
            },
            value: {
              show: true,
              fontFamily: "Inter, sans-serif",
              color: colors.colorText,
              formatter: (w: any) => {
                const total = w.globals.seriesTotals.reduce(
                  (a: number, b: number) => a + b,
                  0
                );
                return Math.round(total).toLocaleString();
              },
            },
            total: {
              show: true,
              label: "Total",
              fontFamily: "Inter, sans-serif",
              color: colors.colorText,
              formatter: (w: any) => {
                const total = w.globals.seriesTotals.reduce(
                  (a: number, b: number) => a + b,
                  0
                );
                return Math.round(total).toLocaleString();
              },
            },
          },
        },
      },
    },
  });

  if (!tableData) return <div>No tableData available</div>;

  return (
    <ConfigProvider
      theme={{
        algorithm:
          themeMode === "light" ? theme.defaultAlgorithm : theme.darkAlgorithm,
        token: themeMode === "light" ? lightModeColors : darkModeColors,
      }}
    >
      <div className="p-6 min-h-screen flex flex-col items-center justify-center space-y-6 transition-all duration-300 ease-in-out">
        <div className="w-full max-w-3xl p-6 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300">
          <ReactApexChart
            options={
              chartType === "bar"
                ? getBarOptions(
                    themeMode === "light" ? lightModeColors : darkModeColors
                  )
                : getDonutOptions(
                    themeMode === "light" ? lightModeColors : darkModeColors
                  )
            }
            series={chartType === "bar" ? series : (series as number[])}
            type={chartType}
            height={400}
          />
        </div>
        <div className="w-full max-w-md space-y-4">
          <div className="flex flex-col space-y-2">
            <label className="font-semibold">Select Column to Display:</label>
            <Select
              value={selectedColumn}
              onChange={handleColumnChange}
              className="w-full"
            >
              {tableData.columns.slice(1).map((col: Column) => (
                <Select.Option key={col.key} value={col.dataIndex}>
                  {col.title}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="flex flex-col space-y-2">
            <label className="font-semibold">Chart Type:</label>
            <Select
              value={chartType}
              onChange={handleChartTypeChange}
              className="w-full"
            >
              <Select.Option value="donut">Donut</Select.Option>
              <Select.Option value="bar">Bar</Select.Option>
            </Select>
          </div>
          {chartType === "donut" ? (
            <div className="flex flex-col space-y-2">
              <label className="font-semibold">
                Top Percentage: {topPercentage}%
              </label>
              <Slider
                min={5}
                max={100}
                value={topPercentage}
                onChange={handleTopPercentageChange}
              />
            </div>
          ) : (
            <div className="flex flex-col space-y-2">
              <label className="font-semibold">
                Number of Bars: {barCount}
              </label>
              <Slider
                min={3}
                max={20}
                value={barCount}
                onChange={handleBarCountChange}
              />
            </div>
          )}
          <div className="flex flex-col space-y-2">
            <Button onClick={toggleSortOrder}>
              Toggle Sort Order: {sortAscending ? "Ascending" : "Descending"}
            </Button>
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
}

// function checkValidity(checkingData) {
//   let count = 0;
//   for (let key in checkingData) {
//       if (checkingData.hasOwnProperty(key)) {
//           let value = checkingData[key];
//           if(value == null){
//               value = 0;
//           }
//           if(isNaN(parseFloat(value))){
//               count++;
//           }
//       }
//   }
//   if(count > 1)return false;
//   return true;
// }
