/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import SidebarLayover from "./SidebarLayover";
import { FireAI_Logo_mini, Sidebar_Hamburger } from "@assets/index";
import { Link } from "@tanstack/react-router";
import { useAppDispatch } from "@store/store";
import { toggleSideBar } from "@store/features/sidebar/SidebarSlice";
import ThemeToggleBtn from "./ThemeToggleBtn";
import { toggleContactUs } from "@store/features/topbar/contactus";
import { SidebarBottomTypes, SidebarDataTypes, UserDetails } from "@interfaces/components/common/sidebar";
import { changeSearchbarValue } from "@store/features/searchBar/SearchbarSlice";
import { changeQueryResponse } from "@store/features/reports/queryResponse";
import { FetchSidebarData } from "@api/common/fetchSidedbar";
import { FetchUserDetails } from "@api/common/fetchUserDetails";
import { sidebarLogos } from "@data/common/sidebar";
import { setUserState } from "@store/features/user/userInfo"

const Sidebar = () => {
  const dispatch = useAppDispatch();
  const [sidebarData, setSidebarData] = useState<SidebarDataTypes[]>([]);
  const [sideBarToolsBottom, setSideBarToolsBottom] = useState<SidebarBottomTypes[]>([]);
  const [userData, setUserData] = useState<UserDetails>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeItem, setActiveItem] = useState<string | null>(null);

  const toggleSidebarFunction = (isOpen: boolean, closeAfterSelect = false) => {
    dispatch(toggleSideBar({ open: isOpen }));
    if (closeAfterSelect) {
      setTimeout(() => dispatch(toggleSideBar({ open: false })), 0); // Close after some delay
    }
  };

  function resetPageValues(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.preventDefault();
    dispatch(changeSearchbarValue({ value: "" }));
    dispatch(changeQueryResponse({ value: null }));
    if (window.location.pathname !== "/dashboard") history.pushState({}, "", "/dashboard");
  }

  useEffect(() => {
    const fetchSideBarData = async () => {
      const res = await FetchSidebarData();
      setSidebarData(res?.sidebarMain || []);
      setSideBarToolsBottom(res?.sideBarBottom || []);
    };

    const fetchUserData = async () => {
      const res = await FetchUserDetails();
      res && dispatch(setUserState(res))
      setUserData(res ? res : undefined);
    };

    fetchSideBarData();
    fetchUserData();
  }, []);

  return (
    <>
      <SidebarLayover
        props={{
          data: sidebarData,
          bottomData: sideBarToolsBottom,
          user: userData,
        }}
      />
      <div
        className="relative w-full overflow-y-hidden text-fireai-blue hidden md:flex h-screen bg-fireai-grey dark:bg-[#1D1D1D]"
        onClick={() => toggleSidebarFunction(true)}
      >
        <div className="w-full mt-5 lg:mt-7 text-center flex flex-col justify-between">
          <div>
            <Link to="/dashboard">
              <img
                alt="Fire AI mini logo"
                src={FireAI_Logo_mini}
                height={30}
                className="mx-auto h-7"
                onClick={resetPageValues}
              />
            </Link>
            <div className="pt-4 pb-3 mt-3">
              <img
                src={userData?.photo}
                alt={`${userData?.name}'s profile pic`}
                className="rounded-full h-3 w-3 md:h-5 md:w-5 lg:h-6 lg:w-6 mx-auto xl:h-7 xl:w-7"
              />
            </div>
            <div className="flex flex-col h-full">
              <div className="flex-grow w-full text-center">
                {sidebarData.map((data, index) => (
                  <div key={index}>
                    {!data.subMenu ? (
                      <Link to={data.location}>
                        <div className="group" onClick={() => toggleSidebarFunction(true, true)}>
                          <span className="hidden sm:group-hover:inline fixed left-12 p-1 rounded-full text-white text-[0.6rem] bg-fireai-blue">
                            {data.mainName}
                          </span>
                          <span className="flex flex-row justify-center cursor-pointer hover:bg-blue-200 py-3">
                            {sidebarLogos[data.icon]}
                          </span>
                        </div>
                      </Link>
                    ) : (
                      <div
                        className="flex flex-row justify-center cursor-pointer hover:bg-blue-200 py-3"
                        onClick={() => {
                              
                          toggleSidebarFunction(true);
                          setActiveItem(data.mainName);
                          
                        }}
                      >
                        {sidebarLogos[data.icon]}
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div className="flex flex-col items-center mt-auto py-3 pt-9">
                <div className="mb-5">
                  <ThemeToggleBtn />
                </div>
                <div className="hover:bg-blue-200 cursor-pointer w-full mx-auto py-3">
                  <img
                    alt="Hamburger"
                    src={Sidebar_Hamburger}
                    height={30}
                    className="mx-auto h-5 w-1/4"
                  />
                </div>
              </div>
            </div>
            <div className="w-full text-center mb-12">
              {sideBarToolsBottom.map((data, index) => {
                if (data.mainName === "Product Demo")
                  return (
                    <div
                      onClick={() => {
                        dispatch(toggleContactUs({ open: true }));
                        
                       
                      }}
                      key={index}
                    >
                      <div className="text-[22px] group">
                        <span className="flex flex-row justify-center cursor-pointer hover:bg-blue-200 py-3">
                          {sidebarLogos[data.icon]}
                        </span>
                      </div>
                    </div>
                  );
                else {
                  return (
                    <Link to={data.location} key={index}>
                      <div className="text-[22px] group" onClick={() => toggleSidebarFunction(true, true)}>
                        <span className="flex flex-row justify-center cursor-pointer hover:bg-blue-200 py-3">
                          {sidebarLogos[data.icon]}
                        </span>
                      </div>
                    </Link>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
