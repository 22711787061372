import { changeFilter, clearFilter } from '@store/features/reports/filterStore'
import { useAppSelector } from '@store/store'
import { ConfigProvider, Select, theme } from 'antd'
import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'

type ReportFilterProps = {
    isLoading: boolean,
    filterOptions: {
        label: string,
        value: string
    }[],
    filterName: string,
    defaultOption: string
}

const ReportFilter: React.FC<ReportFilterProps> = ({ isLoading, filterOptions, filterName, defaultOption }) => {

    const themeMode = useAppSelector((state) => state.theme.themeMode);
    const memoizedData = useMemo(() => {
        if (filterOptions) {
            return [{ label: 'Clear Filter', value: 'Clear Filter' }, ...filterOptions];
        }
        return null;
    }, [filterOptions]);
    const dispatch = useDispatch();
    const filterValue = useAppSelector((state) => state.filterStore.filters[filterName]);
    // const state = useAppSelector((state) => state.filterStore);
    const handleSelectChange = (value: string) => {
        // console.log(state);

        if (value === "Clear Filter") {
            dispatch(clearFilter({ key: filterName }));
        }
        else {
            dispatch(changeFilter({ key: filterName, value: value }));
        }
    }
    return (
        <ConfigProvider
            theme={{
                algorithm: themeMode === "light" ? theme.defaultAlgorithm : theme.darkAlgorithm,
            }}
        >
            <Select
                disabled={isLoading}
                allowClear={true}
                placeholder={`Select ${filterName}`}
                className="w-[200px] xs:w-[100px] md:w-fit md:min-w-[150px] border-[1px] border-fireai-blue rounded-lg outline-none"
                onChange={handleSelectChange}
                options={memoizedData?.map((item) => {
                    return {
                        label: item.label,
                        value: item.value
                    };
                })}
                value={filterValue === null ? `Select ${filterName}` : filterValue}
                defaultValue={defaultOption}
            />
        </ConfigProvider>
    )
}

export default ReportFilter
