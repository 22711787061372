import { useAppDispatch } from "@store/store";
import { setAccessState } from "@store/features/audio/microphoneAcessSlice";

const CheckIfMicrophoneAccessAlreadyPermissionGiven = async () => {
    const dispatch = useAppDispatch()
    try {
        // @ts-expect-error Microphone permission is included in browser type space
        const permissionStatus = await navigator.permissions.query({ name: 'microphone' });
        if (permissionStatus.state === 'granted') {
            dispatch(setAccessState({ access: true }))
            return true
        }
    } catch (error) {
        dispatch(setAccessState({ access: false }))
        return false
    }
    return false
}

export default CheckIfMicrophoneAccessAlreadyPermissionGiven