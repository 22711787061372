import "../../styles/pageLoader.css"

const Cube = ({ h, w, l }) => (
    <div className={`cube h${h} w${w} l${l}`}>
        <div className="face top"></div>
        <div className="face left"></div>
        <div className="face right"></div>
    </div>
);

const CubeContainer = ({ h, count }) => {
    const cubes: JSX.Element[] = [];

    for (let w = 1; w <= count; w++) {
        for (let l = 1; l <= count; l++) {
            cubes.push(<Cube key={`${h}-${w}-${l}`} h={h} w={w} l={l} />);
        }
    }

    return <div className={`h${h}Container`}>{cubes}</div>;
};

const LoaderContainer = () => (
    <div className="h-screen w-screen flex flex-col justify-center bg-black text-center">
        <div className="container mx-auto">
            {[1, 2, 3].map(h => (
                <CubeContainer key={h} h={h} count={3} />
            ))}
        </div>
        <div className="text-white pt-20">
            <p className="text-3xl uppercase">Loading</p>
            <p>Good things take time...</p>
        </div>
    </div>
);

export default LoaderContainer