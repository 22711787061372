import { getAxiosClient } from '@functions/api/axiosInstance'
import { AxiosResponse } from 'axios'
import { DatabaseInstanceType } from '@interfaces/api/common/databaseinstances'
import ReactGA from "react-ga4"

const FetchDataBaseInstance = async (): Promise<DatabaseInstanceType[] | []> => {
    try {
        const _axios = await getAxiosClient()
        const DatabaseInstancesResponse: AxiosResponse<DatabaseInstanceType[]> = await _axios.get<DatabaseInstanceType[]>("/api/query/dbData")
        ReactGA.event({ category: "API Request", action: "Fetch databases and suggestion texts", label: `Success in ${DatabaseInstancesResponse.headers['responseTime'] / 1000}s` });
        return DatabaseInstancesResponse.data
    }
    catch (err) {
        console.error("error while fetching database instances")
        ReactGA.event({ category: "API Request", action: "Fetch databases and suggestion texts", label: `Failure` });
        return []
    }
}

export { FetchDataBaseInstance }