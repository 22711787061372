import { getAxiosClient } from "@functions/api/axiosInstance"
type FeedbackBodyType = {
    was_satisfied: boolean,
    feedback?: string,
    question: string,
    sql_query: string,
    database_instance: number
}

const postFeedback = async (reqBody: FeedbackBodyType) => {
    // console.log('Feedback submitted')
    try {
        const _axios = await getAxiosClient();
        const response = await _axios.post('/api/feedback', reqBody);
        return response.data.message;
    } catch (error) {
        return "error while posting feedback";
    }
}

export default postFeedback;