type DateProps = {
    value: string,
    myCustomFrom?: Date,
    myCustomTo?: Date
}

export const getDates = ({value, myCustomFrom = new Date, myCustomTo = new Date} : DateProps)=>{


    const formatDate = (date: Date): string => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    
    const getStartOfMonth = (date: Date) => {
        return new Date(date.getFullYear(), date.getMonth(), 1);
      };
    
      const getEndOfMonth = (date: Date) => {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59, 999);
      };
    
    const getTodaysDate = () => {
        const start = new Date();
        start.setHours(0, 0, 0, 0);
        const end = new Date();
        end.setHours(23, 59, 59, 999);
        const fromDate = formatDate(start);
        const toDate = formatDate(end);
        return { fromDate, toDate };
    }
    
    const getYesterdayDate = () => {
        const start = new Date();
        start.setDate(start.getDate() - 1);
        start.setHours(0, 0, 0, 0);
        const end = new Date();
        end.setDate(end.getDate() - 1);
        end.setHours(23, 59, 59, 999);
        const fromDate = formatDate(start);
        const toDate = formatDate(end);
        return { fromDate, toDate };
    }
    
    const getLast7daysDate = () => {
        const start = new Date();
        start.setDate(start.getDate() - 7);
        start.setHours(0, 0, 0, 0);
        const end = new Date();
        end.setHours(23, 59, 59, 999);
        const fromDate = formatDate(start);
        const toDate = formatDate(end);
        return { fromDate, toDate };
    }
    
    const getLast30daysDate = () => {
        const now = new Date();
        const start = new Date(now.getTime() - 29 * 24 * 60 * 60 * 1000);
        const end = new Date();
        end.setHours(23, 59, 59, 999);
        const fromDate = formatDate(start);
        const toDate = formatDate(end);
        return { fromDate, toDate };
    }
    
    const getLastMonthDate = () => {
        const now = new Date();
        const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        const startOfLastMonth = getStartOfMonth(lastMonth);
        const endOfLastMonth = getEndOfMonth(lastMonth);
        const fromDate = formatDate(startOfLastMonth);
        const toDate = formatDate(endOfLastMonth);
        return { fromDate, toDate };
    }
    
    const getThisMonthDate = () => {
        const now = new Date();
        const startOfThisMonth = getStartOfMonth(now);
        const endOfThisMonth = getEndOfMonth(now);
        const fromDate = formatDate(startOfThisMonth);
        const toDate = formatDate(endOfThisMonth);
        return { fromDate, toDate };
    }
    
    const getThisYearDate = () => {
        const now = new Date();
        const startOfThisYear = new Date(now.getFullYear(), 0, 1);
        const endOfThisYear = new Date(now.getFullYear(), 11, 31, 23, 59, 59, 999);
        const fromDate = formatDate(startOfThisYear);
        const toDate = formatDate(endOfThisYear);
        return { fromDate, toDate };
    }
    
    const getLastYearDate = () => {
        const now = new Date();
        const lastYear = new Date(now.getFullYear() - 1, 0, 1);
        const startOfLastYear = new Date(lastYear.getFullYear(), 0, 1);
        const endOfLastYear = new Date(lastYear.getFullYear(), 11, 31, 23, 59, 59, 999);
        const fromDate = formatDate(startOfLastYear);
        const toDate = formatDate(endOfLastYear);
        return { fromDate, toDate };
    }
    
    const getCustomDate = (startDate: Date, endDate: Date) => {
        const start = new Date(startDate);
        start.setHours(0, 0, 0, 0);
        const end = new Date(endDate);
        end.setHours(23, 59, 59, 999);
        const fromDate = formatDate(start);
        const toDate = formatDate(end);
        return { fromDate, toDate };
    }
    if(value === "today"){
        return getTodaysDate();
    }
    else if(value === "yesterday"){
        return getYesterdayDate();
    }
    else if(value === "last7days"){
        return getLast7daysDate();
    }
    else if(value === "last30days"){
        return getLast30daysDate();
    }
    else if(value === "lastMonth"){
        return getLastMonthDate();
    }
    else if(value === "thisMonth"){
        return getThisMonthDate();
    }
    else if(value === "thisYear"){
        return getThisYearDate();
    }
    else if(value === "lastYear"){
        return getLastYearDate();
    }
    else if(value === "custom"){
        return getCustomDate(myCustomFrom, myCustomTo);
    }
    else{
        return {fromDate: null, toDate: null};
    }
} 
