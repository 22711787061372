import { DropdownProps } from "@interfaces/components/common/sidebar";
import { Link } from "@tanstack/react-router";
import { useState } from "react";
import UpArrow from "@assets/sidebar/UpArrow.svg";
import DownArrow from "@assets/sidebar/DownArrow.svg";
import { sidebarLogos } from "@data/common/sidebar";

const CustomDropdown: React.FC<DropdownProps> = ({ item, closeSidebar }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="my-2">
            <div
                className={`text-[#3E3E3E] flex flex-row items-center h-5 font-medium text-base mx-2 rounded-md cursor-pointer py-4 ${isOpen ? 'bg-blue-200 text-[#1B41E9]' : 'dark:hover:text-[#1B41E9] dark:text-[#DADADA] hover:bg-blue-200'
                    }`}
                onClick={handleToggle}
            >
                <span className="inline-flex mx-3 justify-center items-center">{sidebarLogos[item.icon]}</span>
                {item.mainName}
                <span className="ml-auto">
                    {isOpen ? (
                        <img src={UpArrow} alt="up-arrow" className="w-2.5 mx-2" />
                    ) : (
                        <img src={DownArrow} alt="down-arrow" className="w-2.5 mx-2" />
                    )}
                </span>
            </div>
            {isOpen && (
                <div className="ml-5 py-1 max-h-[100px] overflow-auto scrollbar-thin ">
                    {item.subMenu?.map((subitem, index) => (
                        <Link
                            to={subitem.location}
                            onClick={closeSidebar}
                            key={index}
                            className="flex cursor-pointer items-center text-sm my-1 text-gray-700 dark:text-white"
                        >
                            <span className="mx-4 w-3 h-3">{sidebarLogos[subitem.icon]}</span>
                            <span className="truncate">{subitem.name}</span>
                            
                        </Link>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CustomDropdown