import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export interface UserInfoProps {
    name?: string | null,
    photo?: string | null,
    email?: string | null,
    preferredLanguage?: string | null
}

const initialState: UserInfoProps | null = {}


export const UserInfoSlice = createSlice({
    name: "UserInfo",
    initialState,
    reducers: {
        setUserState: (state, action: PayloadAction<{ name: string, photo: string, email: string, preferredLanguage: string }>) => {
            state.name = action.payload.name;
            state.photo = action.payload.photo;
            state.email = action.payload.email;
            state.preferredLanguage = action.payload.preferredLanguage;
        },
        setUserLangauge: (state, action: PayloadAction<{ preferredLanguage: string }>) => {
            state.preferredLanguage = action.payload.preferredLanguage
        }
    }
})

export default UserInfoSlice.reducer;
export const { setUserState, setUserLangauge } = UserInfoSlice.actions;