import MicrophoneAccessModal from "./bhashini/microphoneAccess"
import PreferredLanguageInfo from "./bhashini/preferredLanguageInfo"
import PreferredLanguageSelection from "./bhashini/preferredLanguageSelection"
import { useAppSelector } from "@store/store";

const ModalsRoot = () => {
    const modalState = useAppSelector((state) => state.modalState);
    const micPermissionStatus = useAppSelector(state => state.audioAccess)
    return (
        <>
            {(!micPermissionStatus.access && modalState['bhashiniMicrophoneAccess']) ? <MicrophoneAccessModal modalName="bhashiniMicrophoneAccess" /> : null}
            {(modalState['preferredLanguageInfo']) ? <PreferredLanguageInfo modalName="preferredLanguageInfo" /> : null}
            {(modalState['PreferredLanguageSelection']) ? <PreferredLanguageSelection modalName="PreferredLanguageSelection" /> : null}
        </>
    )
}

export default ModalsRoot