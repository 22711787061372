import { PayloadAction, createSlice } from "@reduxjs/toolkit"

interface ModalProps {
    isOpen: boolean
}

const initialState: ModalProps = {
    isOpen: false
}

const ContactUsSlice = createSlice({
    name: "contactus",
    initialState,
    reducers: {
        toggleContactUs: (state, action: PayloadAction<{ open: boolean }>) => {
            state.isOpen = action.payload.open
        }
    }
})

export default ContactUsSlice.reducer;
export const { toggleContactUs } = ContactUsSlice.actions;